import {Paper, Typography, Grid} from "@material-ui/core";
import "./description-card.css";

export default (props) => {
  return (
    <Paper style={{ margin: 0, marginBottom: 10 }}>
      <Grid container>
        {props.mode === "reverse" ? (
          <>
            <Grid item xs={12} sm={6} style={{backgroundImage: `url("${props?.img}")`, width: "100%", height: "18em", backgroundPosition: 'center', backgroundSize: 'cover'}} />
            <Grid
              container
              xs={12}
              sm={6}
              style={{ padding: 10 }}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  style={{ textAlign: "center", color: "#114675", fontSize: 24 }}
                >
                    {props?.title ? props.title : null}
                </Typography>
                <Typography
                  variant="p"
                >
                  {props?.body ? props.body : null}
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
          <Grid
            container
            xs={12}
            sm={6}
            style={{ padding: 10 }}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{ textAlign: "center", color: "#114675", fontSize: 24 }}
              >
                  {props?.title ? props.title : null}
              </Typography>
              <Typography
                variant="p"
              >
                {props?.body ? props.body : null}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} style={{backgroundImage: `url("${props?.img}")`, width: "100%", height: "18em", backgroundPosition: 'center', backgroundSize: 'cover'}} />
        </>
        )}
      </Grid>
    </Paper>
  );
};
