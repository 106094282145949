import React from "react";
import "./Notice.css";
import { Box } from "@material-ui/core";

const Notice = () => {
  return (
    <Box
      sx={[
        {
          display: "flex",
          alignItems: "center",
        },
      ]}
    >
      <div class="notice p-3 ">
        <div className="pr-2">FOR JOB ORIENTED ENGINEERING TRAINING AND PLACEMENT</div>
        <button class="btn btn-primary btn-sm" href="www.sdh.com">
          Contact Us
        </button>
      </div>
    </Box>
  );
};

export default Notice;
