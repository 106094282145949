//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import pipeImg1 from "./../images/pipe_image/pipe_5.gif";
import pipeImg2 from "./../images/pipe_image/pipe_2.jpg";
import pipeImg3 from "./../images/pipe_image/pipe_3.jpg";
import pipeImg4 from "./../images/pipe_image/pipe_4.jpg";


import pipeImgBanner from './../images/slider/3d_printing.jpg'

import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/shared/description-card";
import DescriptionTextContent from "../../components/shared/description-text-content";
import { Box } from "@material-ui/core";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";

export default {
  serviceName: "3d Printing",
  serviceDescription: ``,
  show: true,
  serviceBackgroundImage: pipeImgBanner,
  serviceSubHeading: ``,
  imageList: ["", ""],
  serviceBody: () => (
    <>
      <Container maxWidth="lg">
        <HeadingText title="Full range of fire protection services, including site surveys and technical support" />
      </Container>

      <HeroGreyTextSection
        content="snwel Engineering®’s 3d Printing Team has expertise in designing piping layouts for different industries such as Oil & Gas, Mining & Metals, Pharmaceutical, Paper & Pulp, Water Treatment, Power and Petrochemicals."
        subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "The team is familiar with application of codes and standards such as ANSI, ISO, DIN, IS and ASME, and has executed projects involving simple skid designs to large and complex process plants.",
          "As a value engineering partner to Clients, snwel Engineering® also has expertise in space management analysis and applying key space management concepts to optimize the plant, equipment and piping layout, while ensuring adherence to client specifications, codes and standards.",
        ]}
      />

      {/* <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={image4}
        color={true}
        body={{
          title: "Turbo Compressor Services include",
          ulList: [
            "demo content 1",
            "demo content 2",
            "demo content 3",
            "demo content 4",
            "demo content 5",
          ],
          subContent:
            "We deliver total solutions as a leading independent turbo compressor service provider. With our in depth compressor knowledge and proven technical capabilities, we support customers in repairing, maintaining and optimizing their production facilities in the global oil & gas and petrochemical industries.",
        }}
      /> */}


      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <DescriptionTextContent
          title={"OUR 3D PRINTING SERVICE INCLUDES"}
          bodyListData={[
            {
              title: "Architectural Models",
              img: pipeImgBanner,
              description:
                "ecause 3D printing architectural models offers time savings in the design phase, its niche group of users is growing. 3D printing architectural models provides the opportunity to reduce the number of steps, improving design time, while retaining fine details of the final architecture plan.Computer simulations have been used in engineering and architecture for a long time.",
            },
            {
              title: "Engineering Prototypes",
              img: pipeImgBanner,
              description:
                "When you innovate new designs, 3D printing lets you try new prototypes, addressing problems and finding solutions as you go, it’s all is just a matter of hours. Even those with complex internal structures and geometries, something that traditional methods just can’t offer.",
            },
            {
              title: "Sand Casting Pattern",
              img: pipeImgBanner,
              description:
                "A mold made through pattern pressing on a sand mixture is used in our process of eco-friendly sand casting. A cavity is left by the pattern in which we pour the molten metal. For high-volume production, automated equipment can be very helpful.",
            },
            {
              title: "Interior Accessories",
              img: pipeImgBanner,
              description:
                "When it comes to design, there really is no limit to the imagination. Unfortunately, there is a limit to how much of a designer’s imagination can actually be brought into the real world and turned into a physical object. The limitations of modern fabrication and manufacturing loom large over any product designer’s thought process. When you need to create objects by casting them in molds or by using CNC machines, there are geometries that are simply never going to work.",
            },
          ]}
        />
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>


      <HeroSectionImage
        title={"Benefits of 3d printing for architects"}
        img={image3}
        reverse={false}
        body={{
          ulList: [
            "3D Printed Models is Perfect tool for Critical Architectural shape studies.",
            "3D Printed models can be a valuable way of communicating ideas to colleges as well as towards the client. Your client may not always be capable to read or understand 3D drawings created on a computer.",
            "You will spend less time in the modeling space. Just turn on the 3D Printer and you can continue with other important work while your printer does all the work.",
            "Design freedom: double-curved surfaces and specific details often require considerable time and are difficult to create. With a 3D Printer it is a matter of few hours.",
          ],
        }}
      />

<HeroSectionImage
        title={"Why choose a printer in engineering"}
        img={image3}
        reverse={true}
        body={{
          ulList: [
            "3D printing shrinks the development process from months to a matter of days",
            "You’re able to test ideas quickly and discover what does and doesn’t work. New iterations have no cost penalties",
            "Engineers can discuss a design with peers or rapidly test the market potential at a trade-show",
            "It’s easy to personalize and tweak parts to uniquely fit your needs at no extra cost and in just a matter of hours",
          ],
        }}
      />
      <HeroGreyTextSection
        content="We take pride in our piping designs, we know what piping layouts work and why. We go beyond producing ‘Code Compliance Reports’."
        subHeadingTitle="Why To Choose Us?"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]}
      />

      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <ThreeBoxDetail
          title="SOLUTIONS"
          left={{
            title: "Tailored solutions",
            content:
              "Site surveys and technical support to determine and implement the optimum solution for each situation",
          }}
          right={{
            title: "Tailored solutions",
            content:
              "Site surveys and technical support to determine and implement the optimum solution for each situation",
          }}
          img={image4}
        />
      </Container>

      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content:
              "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "End to End",
            content:
              "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content:
              "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4}
        />
      </Container>
      <button style={{
                  backgroundColor: "rgba(17, 70, 117, .75)",
                  textShadow: '1px 1px 1px #000',
                  color: 'white',
                  marginTop:-42,
                  marginLeft:63,
                  padding: 15,
                  paddingLeft:700, 
                  paddingRight:530,
                  position:"absolute"
                }}
                  >
                <a style={{
                  marginLeft:-140,
                }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
            </button>
    </>
  ),
};
