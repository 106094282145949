//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import pipeImg1 from "./../images/PLANTSCAN.jpg";
import pipeImg2 from "./../images/DIMENSIONCONTROL.jpg";
import pipeImg3 from "./../images/REVERSEENGINEERING.jpg";
import pipeImg4 from "./../images/BUILDINGMODELING.jpg";
import pipeImg5 from "./../images/LASERSURVEY.jpg";
import pipeImg6 from "./../images/HERITAGE.jpg";
import pipeImg7 from "./../images/OTHERS.jpg";
import pipeImg8 from "./../images/service/3d_lazer_scanning_images/image004.jpg";


import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/DescriptionCard";
import DescriptionTextContent from "../../components/shared/description-text-content";

import {
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";
import { Box } from "@material-ui/core";

export default {
  serviceName: "3D LASER SCANNING",
  serviceDescription: ``,
  serviceBackgroundImage: pipeImg4,
  serviceSubHeading: ``,
  imageList: ["", ""],
  show: true,
  serviceBody: () => (
    <><>
      <Container maxWidth="lg">
        <HeadingText title="3D Laser Scanning Will Bring Your Worksite to Your Desktop" />
      </Container>

      <HeroGreyTextSection
        content="snwel Engineering®’s Laser Scaning Team has expertise in laser scaning   for different industries such as Oil & Gas, Mining & Metals, Pharmaceutical, Paper & Pulp, Water Treatment, Power and Petrochemicals."
        subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "The team is familiar with application of codes and standards such as ANSI, ISO, DIN, IS and ASME, and has executed projects involving simple skid designs to large and complex process plants.",
          "As a value engineering partner to Clients, snwel Engineering® also has expertise in space management analysis and applying key space management concepts to optimize the plant, equipment and piping layout, while ensuring adherence to client specifications, codes and standards.",
        ]} />
      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <DescriptionTextContent
          title={"OUR LASER SCANING SERVICE INCLUDES"}
          bodyListData={[
            {
              title: "3D Plant Scanning Services and As-built Documentation",
              img: pipeImg1,
              description: "Our 3D plant scanning services also includes asset and facility management in multiple sectors such as mechanical, electrical and plumbing / piping design.The laser scanners like Faro create detailed and highly accurate 3D models with digitized documentation. These 3D models and documentations can be used for many things like.",
            },
            {
              title: "Large Part Scanning and Dimension control",
              img: pipeImg2,
              description: "In industries such as automotive, aerospace and heavy equipment manufacturing or fabrication, the most time-consuming task is to conduct a large part inspection of the various components.",
            },
            {
              title: "Reverse engineering",
              img: pipeImg3,
              description: "3D scanning services are ideal for reverse engineering applications for in the development of CAD model & Manufacturing Drawing of critical parts in Automotive, Consumer Goods, Power Generation, Cement, Heavy Engineering, Aerospace Industries. Our Reverse engineering services are used into Casting & Forging, Mould & Die, Metal Fabrication, etc.",
            },
            {
              title: "Building Information Modelling",
              img: pipeImg4,
              description: "Strategic and operational planning with legitimate comparison of designed and actual construction requires the Scanning technology. BIM, also known as Building Information Modelling provides an adequate approach to meet the construction requirements from the initial design step to onsite building construction 3D laser scanning with the FARO Laser Scanner aids building design at every stage of construction. With this 3D modelling tool you can compare the current 3D building status with the CAD model at any time – a quality control process which eliminates expensive and time-consuming rework.",
            },
            {
              title: "Laser Surveying & Construction",
              img: pipeImg5,
              description: "Most offshore facilities and platforms require constant maintenance and upgrades. Accurate information on these channels is essential in order to ensure a safe and productive work environment. Our laser surveying scanning services provide 3D construction documentation for internal tools and systems, such as processing plants, surveying facilities or geotechnical engineering and technical equipment of the building.",
            },
            {
              title: "Heritage Scanning",
              img: pipeImg6,
              description: "In this world, preservation of ancient architecture buildings/monuments, artifacts, antiques and archaeology is increasing at faster pace. Specifically, the places which hold some artistic or heritage value needs extra care as well as conservation with highly accurate technology like 3D temple scanning and comprehensive documentation on regular basis, to secure its original value.",
            },
            {
              title: "SCAN TO BIM",
              img: pipeImg3,
              description: "3D scanning services are ideal for reverse engineering applications for in the development of CAD model & Manufacturing Drawing of critical parts in Automotive, Consumer Goods, Power Generation, Cement, Heavy Engineering, Aerospace Industries. Our Reverse engineering services are used into Casting & Forging, Mould & Die, Metal Fabrication, etc.",
            },
            {
              title: "MEDICAL",
              img: pipeImg7,
              description: "We provide highly detailed 3D Scanning to capture the “as-built” condition of the vessel and assist in the design and installation of ballast water management system (BWMS) technologies or Scrubber Exhaust Gas Cleaning System(EGCS) conversions. 3D Modeling demonstrates how either of these systems and associated filters and piping, will fit within the available space for the vessel. 3D Laser scanning of vessels with different types(Bulk carrier, LNG tanker, Chemical Tanker, container vessel, VLCC, etc.) and sizes LOA – 70m to 500m large vessels required 3D Scanning for BWTS, EGCS, or hull scanning for restoration/design verification. Fibrox3D has done 50+ vessel 3D Scanning and Surveying(preliminary survey) of the vessel for BWTS & EGCS onboard. The preliminary survey includes mainly, report preparation, collection of documents, photos, videos of the critical piping system of the vessel. How we do complete engineering project for BWTS & EGCS systems installation onboard explains point-wise.",
            },
              {
              title: "AEROSPACE",
              img: pipeImg7,
              description: "We provide highly detailed 3D Scanning to capture the “as-built” condition of the vessel and assist in the design and installation of ballast water management system (BWMS) technologies or Scrubber Exhaust Gas Cleaning System(EGCS) conversions. 3D Modeling demonstrates how either of these systems and associated filters and piping, will fit within the available space for the vessel. 3D Laser scanning of vessels with different types(Bulk carrier, LNG tanker, Chemical Tanker, container vessel, VLCC, etc.) and sizes LOA – 70m to 500m large vessels required 3D Scanning for BWTS, EGCS, or hull scanning for restoration/design verification. Fibrox3D has done 50+ vessel 3D Scanning and Surveying(preliminary survey) of the vessel for BWTS & EGCS onboard. The preliminary survey includes mainly, report preparation, collection of documents, photos, videos of the critical piping system of the vessel. How we do complete engineering project for BWTS & EGCS systems installation onboard explains point-wise.",
            },
            {
              title: "CRIME SCANNING",
              img: pipeImg7,
              description: "We provide highly detailed 3D Scanning to capture the “as-built” condition of the vessel and assist in the design and installation of ballast water management system (BWMS) technologies or Scrubber Exhaust Gas Cleaning System(EGCS) conversions. 3D Modeling demonstrates how either of these systems and associated filters and piping, will fit within the available space for the vessel. 3D Laser scanning of vessels with different types(Bulk carrier, LNG tanker, Chemical Tanker, container vessel, VLCC, etc.) and sizes LOA – 70m to 500m large vessels required 3D Scanning for BWTS, EGCS, or hull scanning for restoration/design verification. Fibrox3D has done 50+ vessel 3D Scanning and Surveying(preliminary survey) of the vessel for BWTS & EGCS onboard. The preliminary survey includes mainly, report preparation, collection of documents, photos, videos of the critical piping system of the vessel. How we do complete engineering project for BWTS & EGCS systems installation onboard explains point-wise.",
            },
            {
              title: " OTHER SERVICES",
              img: pipeImg7,
              description: "We provide highly detailed 3D Scanning to capture the “as-built” condition of the vessel and assist in the design and installation of ballast water management system (BWMS) technologies or Scrubber Exhaust Gas Cleaning System(EGCS) conversions. 3D Modeling demonstrates how either of these systems and associated filters and piping, will fit within the available space for the vessel. 3D Laser scanning of vessels with different types(Bulk carrier, LNG tanker, Chemical Tanker, container vessel, VLCC, etc.) and sizes LOA – 70m to 500m large vessels required 3D Scanning for BWTS, EGCS, or hull scanning for restoration/design verification. Fibrox3D has done 50+ vessel 3D Scanning and Surveying(preliminary survey) of the vessel for BWTS & EGCS onboard. The preliminary survey includes mainly, report preparation, collection of documents, photos, videos of the critical piping system of the vessel. How we do complete engineering project for BWTS & EGCS systems installation onboard explains point-wise.",
            },
            {
              title: "OTHER SERVICES",
              img: pipeImg7,
              description: "We provide highly detailed 3D Scanning to capture the “as-built” condition of the vessel and assist in the design and installation of ballast water management system (BWMS) technologies or Scrubber Exhaust Gas Cleaning System(EGCS) conversions. 3D Modeling demonstrates how either of these systems and associated filters and piping, will fit within the available space for the vessel. 3D Laser scanning of vessels with different types(Bulk carrier, LNG tanker, Chemical Tanker, container vessel, VLCC, etc.) and sizes LOA – 70m to 500m large vessels required 3D Scanning for BWTS, EGCS, or hull scanning for restoration/design verification. Fibrox3D has done 50+ vessel 3D Scanning and Surveying(preliminary survey) of the vessel for BWTS & EGCS onboard. The preliminary survey includes mainly, report preparation, collection of documents, photos, videos of the critical piping system of the vessel. How we do complete engineering project for BWTS & EGCS systems installation onboard explains point-wise.",
            },
          ]} />
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>
      <HeroSectionImage
        title={"Why Choose Our 3D Scanning Services?"}
        img={pipeImg8}
        color={true}
        reverse={false}
        body= {{ 
          ulList: [
            "High Degree of Accuracy",
            "Virtual As-built Documentation and Data Generation",
            "Existing Model/Drawings Verification",
            "Design and Engineering Verification for Brownfield Project, Ensuring Clash Free Design and Seamless Fit-up",
            "Operations & Maintenance Modification Works, Coordination & Unknown Data Capture",
            "Data Collection for Legal Requirement",
          ],
        }} 
        />
      <HeroSectionImage
        title={"Key Advantage and Technology Application"}
        img={pipeImg8}
        color={true}
        reverse={true}
        body= {{ 
          ulList: [
            "High Degree of Accuracy",
            "Virtual As-built Documentation and Data Generation",
            "Existing Model/Drawings Verification",
            "Design and Engineering Verification for Brownfield Project, Ensuring Clash Free Design and Seamless Fit-up",
            "Operations & Maintenance Modification Works, Coordination & Unknown Data Capture",
            "Data Collection for Legal Requirement",
          ],
        }} 
        />
        

      {/* <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={pipeImg8}
        reverse={true}
        body={{
          ulList: [
            "Pipe Stress & Flexibility Analysis",
            "Piping Detailed Drawings (GA Drawings & Isometrics)",
            "Pipe Support Design and Selection",
            "Material Take Off for Piping, Valves and Insulation",
            "Piping System Revamp Engineering",
            "Piping System Integrity Audit",
            "Laser Scanning & As-Built Modelling for Piping Facilities",
            "Advanced 3D modelling of layout",
          ],
        }} /> */}

      <HeroGreyTextSection
        content="We take pride in our Laser Scaning, we know what Laser Scaning work and why. We go beyond producing ‘Code Compliance Reports’."
        subHeadingTitle="Why To Choose Us?"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]} />

      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content: "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "End to End",
            content: "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content: "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4} />
      </Container>
      <ListGreyTextSection
        title="DIMTEK expertise covering wide application of 3D Laser Scanning, Surveying Works and Dimensional Inspection"
        list={[
          {
            title: "this is dummy title 1",
            content: "Industrial Mapping",
          },
          {
            title: "this is dummy title 2",
            content: "As-Built Site Data Capture",
          },
          {
            title: "this is dummy title 3",
            content: "Ultra Precision Scanning (Accuracy of 0.025 mm)",
          },
          {
            title: "this is dummy title 4",
            content: "As-Built Point Cloud Modeling",
          },
          {
            title: "this is dummy title 5",
            content: "As-Built Intelligent Modeling",
          },
          {
            title: "this is dummy title 6",
            content: "Detail Engineering & Design Modeling",
          },
          {
            title: "this is dummy title 7",
            content: "Topography Survey",
          },
          {
            title: "this is dummy title 7",
            content: "Hydrography Survey ",
          },
          {
            title: "this is dummy title 7",
            content: "Aerial Survey By Drone/Fixed Wing",
          },
          {
            title: "this is dummy title 7",
            content: "As-Built Dimensional Check",
          },
          {
            title: "this is dummy title 7",
            content: "Fabrication Dimensional Monitoring",
          },
          {
            title: "this is dummy title 7",
            content: "Tank Deformation Inspection",
          },
          {
            title: "this is dummy title 7",
            content: "Tank Calibration",
          },
          {
            title: "this is dummy title 7",
            content: "Surface Deformation Analysis",
          },
          {
            title: "this is dummy title 7",
            content: "Stockpile Calculation",
          },
        ]} />
      <Container style={{ marginTop: 75, marginBottom: 75 }}>
        <DescriptionCard
          title="Get A Quote"
          image={pipeImg2}
          content={<Box style={{ paddingBottom: 55 }}>
            Are you in need of fast and high quality service on Piping
          </Box>}
           />
           <button style={{
                  backgroundColor: "rgba(17, 70, 117, .75)",
                  textShadow: '1px 1px 1px #000',
                  color: 'white',
                  marginTop:30, 
                  padding: 15,
                  paddingLeft:700, 
                  paddingRight:530,
                  position:"absolute"
                }}
                  >
                <a style={{
                  marginLeft:-140,
                }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
            </button>
      </Container>
      
    </>

      </>
  ),
};
