const apiUrl = "http://localhost:9876"
// const apiUrl = "https://snwel-backend-2-livid.vercel.app"

export async function submitEnquiry(data) {
    const response = await fetch(new URL( '/snwel-enquiry', apiUrl), {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });

    const resData = await response.json();
    return resData;
}


export async function generateOtp(email, phone) {
    const response = await fetch(
        new URL('/otp/generate', apiUrl),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                phone,
                action: 'snwel-com'
            })
        }
    );

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const resData = await response.json();
    return resData;
}


export async function verifyOtp(otp, token) {
    const response = await fetch(
        new URL('/otp/verify', apiUrl),
        {
            method: 'post',
            headers: {
                "x-otp-token": token,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                otp
            })
        });

    const resData = await response.json();
    return resData;

}