import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box, Container } from "@material-ui/core";

import Divider from "@material-ui/core/Divider";
import { v4 as uuidv4 } from "uuid";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";

import HomeIcon from "@material-ui/icons/Home";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LOGO from "./Data/images/Logo/snwelLogo_Registered_Office.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Notice from "./components/Notice";

export function MobileNavbar(props) {
  console.log("MOBILE NAV: ", props);
  const drawerWidth = "100%";
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      marginBottom: "60px",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `${drawerWidth}`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#229fd4",
      fontFamily: "Myriad Pro Bold",
      // color: 'white'
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    treeView: {
      height: 240,
      flexGrow: 1,
      maxWidth: 400,
    },
    TreeNodeStyle: {
      fontSize: 18,
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const generateSubMenu = (menu) => {
    if (!menu) return null;
    let res = [];
    for (let subMenu of menu) {
      if (subMenu.hasChild) {
        res.push(
          subMenu.childs ? (
            <TreeItem
              nodeId={uuidv4()}
              label={<span style={{ fontSize: 18 }}>{subMenu.name}</span>}
              style={{
                padding: 10,
                color: "#fff",
              }}
            >
              {generateSubMenu(subMenu.childs)}
            </TreeItem>
          ) : (
            <TreeItem
              nodeId={uuidv4()}
              onClick={handleDrawerClose}
              className={classes.TreeNodeStyle}
              label={<span style={{ fontSize: 18 }}>{subMenu.name}</span>}
              style={{
                padding: 10,
                color: "#fff",
              }}
            />
          )
        );
      } else {
        res.push(
          <Link to={`${subMenu.link}`} style={{ textDecoration: "none" }}>
            <TreeItem
              nodeId={uuidv4()}
              onClick={handleDrawerClose}
              className={classes.TreeNodeStyle}
              label={<span style={{ fontSize: 18 }}>{subMenu.name}</span>}
              style={{
                padding: 10,
                color: "#fff",
              }}
            ></TreeItem>
          </Link>
        );
      }
    }
    return res;
  };
  return (
    <div className={classes.root} style={{ marginBottom: 0, marginTop: '50px' }}>
      
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ width: "100%", top: '60px' }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Swnel
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <Container
          style={{ background: "rgba(255,255,255, 0.5)", padding: 10 }}
        >
          <Grid container style={{ marginTop: 10 }}>
            <Box style={{ marginBottom: "20px" }}>
              <Link to="/">
                {" "}
                <img src={LOGO} style={{ width: "80%" }} />
              </Link>
            </Box>
          </Grid>
        </Container>
        <br />
        <br />

        <TreeView
          className={classes.treeView}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          {props.menu.map((menuItem, index) =>
            menuItem.childs ? (
              <TreeItem
                nodeId={index}
                className={classes.TreeNodeStyle}
                label={<span style={{ fontSize: 18 }}>{menuItem.name}</span>}
                style={{
                  color: "#fff",
                  borderBottom: "1px solid white",
                  margin: 5,
                  padding: 10,
                }}
              >
                {generateSubMenu(menuItem.childs)}
              </TreeItem>
            ) : (
              <Link
                to={`/search/${menuItem.link}`}
                style={{ textDecoration: "none" }}
              >
                <TreeItem
                  nodeId={index}
                  onClick={handleDrawerClose}
                  className={classes.TreeNodeStyle}
                  label={<span style={{ fontSize: 18 }}>{menuItem.name}</span>}
                  style={{
                    padding: 10,
                    fontSize: 20,
                    color: "#fff",
                    borderBottom: "1px solid white",
                    margin: 5,
                  }}
                />
              </Link>
            )
          )}
        </TreeView>
      </Drawer>
    </div>
  );
}

export const RouteStatus = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));
  let history = useHistory();
  const [pathList, setPathList] = useState(
    history.location.pathname.split("/") || []
  );
  useEffect(() => {
    return history.listen((location) => {
      setPathList(location.pathname.split("/") || []);
    });
  }, [history]);
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      xs={12}
      style={{ padding: 10, backgroundColor: "#229ED5", color: "#fff" }}
    >
      <Container className={classes.root} style={{ color: "#000" }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="/"
          style={{ color: "rgba(255, 255, 255, 0.38)" }}
        >
          {pathList.map((path, index) =>
            index === 0 ? (
              <Link
                to="/"
                style={{ color: "rgba(255, 255, 255, 0.38)", padding: 2 }}
              >
                <HomeIcon />
              </Link>
            ) : (
              <Typography
                style={{
                  color:
                    pathList.length - 1 === index
                      ? "#fff"
                      : "rgba(255, 255, 255, 0.38)",
                  padding: 2,
                }}
              >
                {path.split("_").join(" ")}
              </Typography>
            )
          )}
        </Breadcrumbs>
      </Container>
    </Grid>
  );
};
