//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import pipeImg1 from "./../images/pipe_image/pipe_1.jpg";
import pipeImg2 from "./../images/pipe_image/pipe_2.jpg";
import pipeImg3 from "./../images/pipe_image/pipe_3.jpg";
import pipeImg4 from "./../images/MEP.jpg";

import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "./../../components/DescriptionCard";
import DescriptionTextContent from "../../components/shared/description-text-content";

import {
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";
import { Box } from "@material-ui/core";

export default {
  serviceName: "MEP ENGINEERING",
  serviceDescription: ``,
  serviceBackgroundImage: pipeImg4,
  serviceSubHeading: ``,
  imageList: ["", ""],
  show: true,
  serviceBody: () => (
    <>
      <Container maxWidth="lg">
        <HeadingText title="MEP Design and Drafting Services" />
      </Container>

      <HeroGreyTextSection
        content="snwel Engineering®’s Mep Engineering Team has expertise in designing piping layouts for different industries such as Oil & Gas, Mining & Metals, Pharmaceutical, Paper & Pulp, Water Treatment, Power and Petrochemicals."
        subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "The team is familiar with application of codes and standards such as ANSI, ISO, DIN, IS and ASME, and has executed projects involving simple skid designs to large and complex process plants.",
          "As a value engineering partner to Clients, snwel Engineering® also has expertise in space management analysis and applying key space management concepts to optimize the plant, equipment and piping layout, while ensuring adherence to client specifications, codes and standards.",
        ]}
      />
      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <DescriptionTextContent
          title={"OUR MEP ENGINEERING SERVICE INCLUDES"}
          bodyListData={[
            {
              title: "Vast Spectrum of MEP Engineering Service",
              img: pipeImg4,
              description:
                "eLogicTech is a well-known MEP documentation and drafting services firm worldwide. Providing quality MEP engineering services at a competitive cost, eLogicTech's range of services includes but are not limited to the following.",
            },
            {
              title: "Mechanical Drafting Service",
              img: pipeImg4,
              description:
                "Mechanical drafting service from eLogicTech makes your entire mechanical engineering process hassle-free. eLogicTech helps you visualize your concept designs, analyze, and detail them with the latest software and infrastructure.",
            },
            {
              title: "MEP Electrical Design and Drafting",
              img: pipeImg4,
              description:
                "Offering exceptional electrical designs, eLogicTech details the electrical schematics, Single Line Diagrams, and electrical site plans with zero-error. eLogicTech also helps in creating a complete electrical plan and suggest improvements in your existing electrical layouts.",
            },
            {
              title: "MEP Conversion Service",
              img: pipeImg4,
              description:
                "With the MEP conversion service, our skilled drafters can help you in converting legacy electrical drawings or concept sketches into MEP engineering compliant CAD designs. Our MEP CAD services are not only accurate but cost-effective.",
            },
          ]}
        />
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>
      <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={image4}
        color={true}
        body={{
          title: "Turbo Compressor Services include",
          ulList: [
            "Mechanical Drafting",
            "Plumbing and Firefighting",
            "Electrical Design and Drafting",
            "MEP Conversion",
            "COMcheck",
          ],
          subContent:
            "We deliver total solutions as a leading independent turbo compressor service provider. With our in depth compressor knowledge and proven technical capabilities, we support customers in repairing, maintaining and optimizing their production facilities in the global oil & gas and petrochemical industries.",
        }}
      />

      <HeroGreyTextSection
        content="We take pride in our piping designs, we know what piping layouts work and why. We go beyond producing ‘Code Compliance Reports’."
        subHeadingTitle="Why To Choose Us?"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]}
      />

      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content:
              "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "End to End",
            content:
              "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content:
              "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4}
        />
      </Container>
      <ListGreyTextSection
        title="Benefits of eLogicTech's MEP Design and Drafting Services"
        list={[
          {
            title: "this is dummy title 1",
            content:
              "EXPERIENCED AND QUALIFIED ENGINEERSS",
          },
          {
            title: "this is dummy title 2",
            content: "BEST QUALITY SERVICE ADHERING TO TIMELINES",
          },
          {
            title: "this is dummy title 3",
            content: "LATEST SOFTWARE AND ADVANCED INFRASTRUCTURE",
          },
      
        ]}
      />
      <Container style={{ marginTop: 75, marginBottom: 75 }}>
        <DescriptionCard
          title="Get A Quote"
          image={pipeImg2}
          content={
            <Box style={{ paddingBottom: 55 }}>
              Are you in need of fast and high quality service on Piping
              Engineering.
            </Box>
          }
         
        />
                   <button style={{
                  backgroundColor: "rgba(17, 70, 117, .75)",
                  textShadow: '1px 1px 1px #000',
                  color: 'white',
                  marginTop:30, 
                  padding: 15,
                  paddingLeft:700, 
                  paddingRight:530,
                  position:"absolute"
                }}
                  >
                <a style={{
                  marginLeft:-140,
                }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
            </button>
      </Container>
    </>
  ),
};
