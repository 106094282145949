import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { Button, Tabs, Tab, MenuItem, Grid, Container } from '@material-ui/core';
import {
    TextField,
    Select,
} from 'mui-rff';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';


import logo from './Data/images/Logo/snwelLogo_Registered_Office.png'
import DescriptionCard from './components/DescriptionCard';


// https://codesandbox.io/s/react-final-form-material-ui-example-tqv09


export default (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
        multilineColor: {
            color: 'black',
            backgroundColor: 'white'
        }
    }));

    const classes = useStyles();
    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Please  your Name';
        }
        if (!values.email) {
            errors.email = 'Required';
        }
        if (!values.inquiry) {
            errors.inquiry = 'Please Select a reason for inquiry';
        }
        return errors;
    };

    const formFields = [
        {
            size: 6,
            field: (
                <TextField
                    label="Name"
                    name="name"
                    margin="none"
                    required={true}
                    variant="outlined"
                    style={{ width: '100%' }}
                    InputProps={{
                        className: classes.multilineColor
                    }}
                />
            ),
        },
        {
            size: 6,
            field: (
                <TextField
                    label="Company Name"
                    name="companyName"
                    margin="none"
                    variant="outlined"
                    style={{ width: '100%' }}
                    InputProps={{
                        className: classes.multilineColor
                    }}
                />
            ),
        },
        {
            size: 12,
            field: (
                <TextField
                    label="Email"
                    name="email"
                    margin="none"
                    required={true}
                    variant="outlined"
                    style={{ width: '100%' }}
                    InputProps={{
                        className: classes.multilineColor
                    }}
                />
            ),
        },
        {
            size: 12,
            field: (
                <TextField
                    label="Telephone"
                    name="telephone"
                    margin="none"
                    variant="outlined"
                    style={{ width: '100%' }}
                    InputProps={{
                        className: classes.multilineColor
                    }}
                />
            ),
        },
        {
            size: 6,
            field: (
                <Select
                    name="inquiry"
                    label="Area of Interest"
                    formControlProps={{ margin: 'none' }}
                    style={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                    variant="outlined"
                    InputProps={{
                        className: classes.multilineColor
                    }}
                    required={true}
                >
                    <MenuItem value="ge">General Inquiry</MenuItem>
                    <MenuItem value="t2">Type 2</MenuItem>
                    <MenuItem value="t3">Type 3</MenuItem>
                    <MenuItem value="t4">Type 3</MenuItem>
                </Select>
            ),
        },
        {
            size: 12,
            field: (
                <TextField
                    name="message"
                    variant="outlined"
                    formControlProps={{ margin: 'none' }}
                    style={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                    placeholder="Enter Your message"
                    InputProps={{
                        className: classes.multilineColor
                    }}
                    multiline
                    rows={15}
                    rowsMax={20}
                />
            ),
        },

    ]
    const onSubmit = (values) => {
        console.log(values)
    };

    return (
        <>{
            (props?.mode == "showForm") ?
                <Grid Container style={{ backgroundColor: '#cfe8fc' }}>
                    <Container maxWidth="md" style={{ padding: 20, marginTop: 0 }}>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{}}
                            validate={validate}
                            render={({ handleSubmit, form, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit} >
                                    <Grid container alignItems="flex-start" spacing={2}>
                                        {
                                            formFields.map((item, idx) => (
                                                <Grid item xs={item.size} key={idx}>
                                                    {item.field}
                                                </Grid>
                                            ))
                                        }
                                        <Grid item style={{ marginTop: 10, width: '100%' }}>
                                            <Button
                                                style={{ width: '100%', padding: '10px' }}
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className="buttonSubmit"
                                                onClick={(event) => { event.preventDefault(); handleSubmit(values) }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        />
                    </Container>
                </Grid> : <Grid conatiner
                    style={{ backgroundColor: 'rgb(34, 158, 213)' }}
                >
                    <Container item maxWidth="lg" style={{ textAlign: 'center' }}>
                        <Grid container
                            direction="row"
                            justify="center"
                            alignItems="flex-start">
                            {/* <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={() => { }}
                                style={{ padding: 5 }}
                                aria-label="disabled tabs example"
                            >
                                <a href=""><Tab style={{ color: '#fff' }} icon={<FacebookIcon style={{ fontSize: '40px' }} />} /></a>
                                <a href=""><Tab style={{ color: '#fff' }} icon={<TwitterIcon style={{ fontSize: '40px' }} />} /></a>
                                <a href=""><Tab style={{ color: '#fff' }} icon={<YouTubeIcon style={{ fontSize: '40px' }} />} /></a>
                                <a href="mailto:contact@snwel.com"><Tab style={{ color: '#fff' }} icon={<EmailIcon style={{ fontSize: '40px' }} />} /></a>
                                <a href=""><Tab style={{ color: '#fff' }} icon={<InstagramIcon style={{ fontSize: '40px' }} />} /></a>
                            </Tabs> */}
                        </Grid>
                    </Container>

                </Grid>
        }
           { (props?.mode == "showForm") ? null : 
            //  SECOND LAYER OF FOOTER
           <Container maxWidth="lg" style={{ textAlign: 'center', padding: 10 }}>
                <Grid container direction="row" justify="center"
                            alignItems="center">
                    <Grid item xs={2}>
                        {/* <img src={logo} style={{ width: '100%', maxWidth: 130 }} /> */}
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start">
                            {/* <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={() => { }}
                                style={{ padding: 5 }}
                            >
                                <a href="" style={{textDecoration: 'none', borderRight: '1px solid white'}}><Tab style={{ color: '#000' }} label="© 2021 SNWEL"/></a>
                                <a href="" style={{textDecoration: 'none', borderRight: '1px solid white'}}><Tab style={{ color: '#000' }} label="Privacy Policy"/></a>
                                <a href="" style={{textDecoration: 'none', borderRight: '1px solid white'}}><Tab style={{ color: '#000' }} label="Terms And Conditons"/></a>
                            </Tabs> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>}
        </>

    )
}