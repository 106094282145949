import { Grid, Box, CardContent, Card, Typography, Container } from "@material-ui/core";
import "./baseText.css";

export const ThreeBoxDetail = (props) => {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item lg={12}>
        <Typography
          variant="h5"
          gutterBottom
          className="ThreeBoxDetail-Heading"
          style={{
            color: "#114675",
            fontSize: 26,
            fontWeight: 500,
            lineHeight: "24px important",
          }}
        >
          <center>{props?.title}</center>
        </Typography>
      </Grid>
      <Container maxWidth={"lg"}>
        <Grid container style={{ margin: 5, padding: 20 }}>
          <Grid
            item
            lg={4}
            sm={4}
            style={{ backgroundColor: "#229FD4", padding: 10 }}
          >
            <Typography
              variant="h5"
              gutterBottom
              className="ThreeBoxDetail-Heading"
            >
              {props?.left?.title}
            </Typography>
            <p variant="p" className="ThreeBoxDetail-Content">
              {props?.left?.content}
            </p>
          </Grid>
          <Grid item lg={4} sm={4}>
            {/* IMG */}
            <img style={{ width: "100%", height: "100%" }} src={props.img} />
          </Grid>
          <Grid item lg={4} sm={4} style={{ backgroundColor: "lightgrey" }}>
            <Typography
              variant="h5"
              gutterBottom
              className="ThreeBoxDetail-Heading"
              style={{ color: "#114675", padding: 10 }}
            >
              {props?.right?.title}
            </Typography>
            <p className="ThreeBoxDetail-Content" style={{ color: "#114675" }}>
              {props?.right?.content}
            </p>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export const HeadingText = (props) => {
  return (
    <Typography
      variant="h4"
      gutterBottom
      className="ThreeBoxDetail-Heading"
      style={{
        color: "#114675",
        padding: 10,
        fontSize: 33,
        fontStyle: "italic",
        textAlign: "center",
      }}
    >
      {props?.title}
    </Typography>
  );
};

const AddValueBox = (props) => {

  return (
    <Card
      square
      style={{
        backgroundColor: props.backgroundColor,
        color: props.color,
        paddingBottom: 35,
        height: '100%'
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="h2"
          style={{ paddingBottom: 35 }}
        >
          {props.title}
        </Typography>
        <Typography
          style={{ color: props.color, opacity: 0.85 }}
        >
          {props.content}
        </Typography>

      </CardContent>
    </Card>
  );
}
export const AddValue = (props) => {

  return (
    <>
      <Box>
        <Typography
          variant="h5"
          className="ThreeBoxDetail-Heading"
          style={{
            textAlign: 'center',
            paddingBottom: 50,
            color: "#114675",
            fontSize: 26,
            fontWeight: 500,
            lineHeight: "24px important",
          }}
        >{props?.title}
        </Typography>
      </Box>
      <Container maxWidth={"lg"} style={{ paddingBottom: 75 }}>
        <Grid container justify="center" spacing={1}>
          <Grid item md={4}>
            <AddValueBox
              title={props?.left?.title}
              content={props?.left?.content}
              backgroundColor="#EBEBEB"
            />
          </Grid>
          <Grid item md={4}>
            <AddValueBox
              title={props?.center?.title}
              content={props?.center?.content}
              backgroundColor="#229fd4"
              color="white"
            />
          </Grid>
          <Grid item md={4}>
            <AddValueBox
              title={props?.right?.title}
              content={props?.right?.content}
              backgroundColor="#114675"
              color="white"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export const HeroGreyTextSection = (props) => {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{
        backgroundColor: "#ebebeb",
        padding: 15,
      }}
    >
      <Container maxWidth="md">
        <Grid item xs={12} style={{ margin: "10px 0" }}>
          <Grid item xs={12} md={12} style={{ paddingLeft: 25 }}>
            <p
              className="ThreeBoxDetail-Content"
              style={{ color: "#114675", fontSize: 16 }}
            >
              {props?.content}
            </p>
            <p
              className="ThreeBoxDetail-Content"
              style={{ color: "#114675", fontSize: 17, fontWeight: 600 }}
            >
              {props?.subHeadingTitle}
            </p>
            {props?.paragraphsList?.map((paragraphs) => (
              <>
                <p
                  className="ThreeBoxDetail-Content"
                  style={{ color: "#114675", fontSize: 16, marginBottom: 20 }}
                >
                  {paragraphs}
                </p>
              </>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export const ListGreyTextSection = (props) => {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      style={{
        backgroundColor: "#ebebeb",
        padding: 40,
        marginBottom: 40
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        className="ThreeBoxDetail-Heading"
        style={{
          color: "#114675",
          fontSize: 26,
          fontWeight: 500,
          lineHeight: "24px important",
        }}
      >
        <center>{props?.title}</center>
      </Typography>
      <Container maxWidth="lg" style={{ margin: '0 auto' }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          xs={12}
          className="ListGrey-Container"
        >
          <Grid item xs={12} sm={6}>
            <ul style={{ textAlign: 'left' }}>
              {props.list.map((item, index) =>
                index % 2 === 0 ? <ListItem {...item} /> : null
              )}
            </ul>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ul style={{ textAlign: 'left' }}>
              {props.list.map((item, index) =>
                index % 2 === 1 ? <ListItem {...item} /> : null
              )}
            </ul>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

const ListItem = (props) => {
  return (
    <li className="ListGreyTextSection-ListItem" style={{ padding: 5 }}>
      <span
        style={{
          fontWeight: 500,
          fontSize: 14,
          textTransform: "capitalize",
          color: "#114675",
          padding: 0,
          margin: 0
        }}
      >
        {props.content}
      </span>
      {/* <br />
      <span
        style={{
          fontWeight: 400,
          fontSize: 14,
          textTransform: "capitalize",
          color: "#114675",
        }}
      >
        {props.content}
      </span> */}
    </li>
  );
};
