//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import pipeImg1 from "./../images/HAZID.png";
import pipeImg2 from "./../images/pipe_image/pipe_2.jpg";
import pipeImg3 from "./../images/pipe_image/pipe_3.jpg";
import pipeImg4 from "./../images/SAFETY.jpg";

import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/DescriptionCard";
import DescriptionTextContent from "../../components/shared/description-text-content";

import {
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";
import { Box } from "@material-ui/core";

export default {
  serviceName: "SAFETY",
  serviceDescription: ``,
  serviceBackgroundImage: pipeImg4,
  serviceSubHeading: ``,
  imageList: ["", ""],
  show: true,
  serviceBody: () => (
    <><>
      <Container maxWidth="lg">
        <HeadingText title="Introduction to Process Safety Reviews" />
      </Container>

      <HeroGreyTextSection
        content="snwel Engineering®’s Safety Team has expertise in designing piping layouts for different industries such as Oil & Gas, Mining & Metals, Pharmaceutical, Paper & Pulp, Water Treatment, Power and Petrochemicals."
        subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "The team is familiar with application of codes and standards such as ANSI, ISO, DIN, IS and ASME, and has executed projects involving simple skid designs to large and complex process plants.",
          "As a value engineering partner to Clients, snwel Engineering® also has expertise in space management analysis and applying key space management concepts to optimize the plant, equipment and piping layout, while ensuring adherence to client specifications, codes and standards.",
        ]} />
      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <DescriptionTextContent
          title={"OUR SAFETY ENGINEERING SERVICE INCLUDES"}
          bodyListData={[
            {
              title: "HAZID & HIRA",
              img: pipeImg1,
              description: "Hazard Identification (HAZID) studies are typically conducted early-on in the design phase of a project to elicit the major accident hazards (MAHs). The HAZID is kept up to date for operational facilities and so is reviewed and updated periodically to ensure any changes to the organisation",
            },
            {
              title: "Hazard and Operability (HAZOP) Study",
              img: pipeImg1,
              description: "A Hazard and Operability study - or HAZOP for short - is an industry standard technique, a team-based assessment of a process using a structured methodology to brainstorm the causes of deviations from normal operating conditions.",
            },
            {
              title: "Layers of Protection Analysis (LOPA)",
              img: pipeImg1,
              description: "LOPA is an industry standard technique, a team-based assessment of a process using a structured methodology to assign a safety integrity level to a safety instrumented function (SIF).",
            },
            {
              title: "Material Take Off for Piping, Valves and Insulation",
              img: pipeImg1,
              description: "Inherently safer design (ISD) is a philosophy for addressing safety issues in the design and operation of processes. When considering ISD it is attempted to manage process risks by eliminating or significantly reducing hazards",
            },
          ]} />
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>

      <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={image3}
        reverse={true}
        body={{
          ulList: [
            "Pipe Stress & Flexibility Analysis",
            "Piping Detailed Drawings (GA Drawings & Isometrics)",
            "Pipe Support Design and Selection",
            "Material Take Off for Piping, Valves and Insulation",
            "Piping System Revamp Engineering",
            "Piping System Integrity Audit",
            "Laser Scanning & As-Built Modelling for Piping Facilities",
            "Advanced 3D modelling of layout",
          ],
        }} />

      <HeroGreyTextSection
        content="We take pride in our piping designs, we know what piping layouts work and why. We go beyond producing ‘Code Compliance Reports’."
        subHeadingTitle="Why To Choose Us?"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]} />

      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content: "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "End to End",
            content: "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content: "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4} />
      </Container>
      <Container style={{ marginTop: 20, marginBottom: 75 }}>
        <DescriptionCard
          title="Get A Quote"
          image={pipeImg2}
          content={<Box style={{ paddingBottom: 55 }}>
            Are you in need of fast and high quality service on Piping
          </Box>}

          targetLink="redirect"
          targetLinkText="Get " />
          <button style={{
                  backgroundColor: "rgba(17, 70, 117, .75)",
                  textShadow: '1px 1px 1px #000',
                  color: 'white',
                  marginTop:30, 
                  padding: 15,
                  paddingLeft:700, 
                  paddingRight:530,
                  position:"absolute"
                }}
                  >
                <a style={{
                  marginLeft:-140,
                }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
            </button>

      </Container>
    </>

      </>
  ),
};
