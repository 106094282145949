import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import civilImg1 from "./../images/pipe_image/pipe_1.jpg";
import civilImg2 from "./../images/pipe_image/pipe_2.jpg";
import civilImg3 from "./../images/pipe_image/pipe_3.jpg";
import civilImg4 from "./../images/pipe_image/pipe_4.jpg";
import civilImgBanner from './../images/slider/Civil-Engineering-Banner.jpg'

import { Link, NavLink } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

// COMPONENTS IMPORT
import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "./../../components/DescriptionCard";
import DescriptionTextContent from "../../components/shared/description-text-content";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";
import { Box } from "@material-ui/core";

export default {
  serviceName: "Civil & Structural Engineering",
  serviceDescription: ``,
  serviceBackgroundImage: civilImgBanner,
  serviceSubHeading: ``,
  imageList: ["", ""],
  show: true,
  serviceBody: () => (
    <>
      <Container maxWidth="lg">
        <HeadingText title="Construct Sustainable And Energy Efficient Structures With Our Civil & Structural Engineering Design Capabilities"/>
      </Container>

      <HeroGreyTextSection
        content="snwel Engineering®’s Civil & Structural Engineering has expertise in designing piping layouts for different industries such as Oil & Gas, Mining & Metals, Pharmaceutical, Paper & Pulp, Water Treatment, Power and Petrochemicals."
        subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "Civil & Structural Engineering The team is familiar with application of codes and standards such as ANSI, ISO, DIN, IS and ASME, and has executed projects involving simple skid designs to large and complex process plants.",
          "As a value engineering partner to Clients, snwel Engineering® also has expertise in space management analysis and applying key space management concepts to optimize the plant, equipment and piping layout, while ensuring adherence to client specifications, codes and standards.",
        ]}
      />
      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <DescriptionTextContent
          title={"OUR CIVIL & STRUCTURAL ENGINEERING SERVICE INCLUDES"}
          bodyListData={[
            {
              title: "Design of pile and shallow foundations",
              img: civilImgBanner,
              description:
                "Design of pile and shallow foundations, Mat foundations, Retaining Walls, Piles/Drilled Shafts and more.",
            },
            {
              title: "Equipment foundations design",
              img: civilImgBanner,
              description:
                "Equipment foundations design for Vertical Vessels, Heaters, Storage Tanks, Pumps, and Machinery.",
            },
            {
              title: "Finite Element Analysis",
              img: civilImgBanner,
              description:
                "Finite Element Analysis of foundations and plant building designs such as Substation, and Maintenance Buildings.",
            },
            {
              title: "Deliverables include but not limited to PCC",
              img: civilImgBanner,
              description:
                "Deliverables include but not limited to PCC, Shuttering, Pile Layouts, Foundation Layouts, Design Calculation Reports, Guide Drawings, Concrete Standard Drawings, Formworks and Detailed Civil drawings, Excavation Back Filling Surplus drawings, and As-built drawings.",
            },
            {
              title: "Structural Design & Analysis ",
              img: civilImgBanner,
              description:
                "Structural Design & Analysis of various kinds of Industrial, High Rise & Plant Structures like Pipe Racks, Cable Racks, Process/ Technological Structures, Skids, Shelter/Shed Structures, Platforms, Metering Stations, and other Plant Structures like Crane girder Structures. Furthermore, as part of Rishabh Engineering’s structural engineering service offering we carry out retrofitting and rehabilitation of structures, Finite element modeling, and analysis (FEM/FEA).",
            },
            {
              title: "Our conceptual studies",
              img: civilImgBanner,
              description:
                "Our conceptual studies of the modularization with the design of modular structures help better understand lifting, transport, and operating conditions.",
            },
            {
              title: "Reverse Engineering services",
              img: civilImgBanner,
              description:
                "Reverse Engineering services like modification or expansion of the existing structures, building retrofitting, and more.",
            },
            {
              title: "Material Take Off for Piping, Valves and Insulation",
              img: civilImgBanner,
              description:
                "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
          ]}
        />
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>
      <HeroSectionImage
        title={"Industries Served"}
        img={civilImgBanner}
        color={true}
        body={{
          title: "Turbo Compressor Services include",
          ulList: [
            "Oil & Gas",
            "Petrochemical & Refinery",
            "Energy & Utility",
            "Specialty Chemical",
            "Manufacturing",
            "Water & Waste Water Treatment"


          ],
          subContent:
            "We deliver total solutions as a leading independent turbo compressor service provider. With our in depth compressor knowledge and proven technical capabilities, we support customers in repairing, maintaining and optimizing their production facilities in the global oil & gas and petrochemical industries.",
        }}
      />

      <HeroSectionImage
        title={"Software / Tools/ Technology"}
        img={image3}
        reverse={true}
        body={{
          ulList: [
            "Trimble TEKLA Structure",
            "RISA",
            "RISA FOUNDATION",
            "SAFE",
            "Bently systems STAAD PRO",
            "Bently systemss prosteel",
            "Laser Scanning & As-Built Modelling for Piping Facilities",
            "Hexagon CADWorx",
          ],
        }}
      />

      <HeroGreyTextSection
        content="We take pride in our piping designs, we know what piping layouts work and why. We go beyond producing ‘Code Compliance Reports’."
        subHeadingTitle="Why To Choose Us?"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]}
      />

      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content:
              "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "End to End",
            content:
              "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content:
              "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4}
        />
      </Container>
      <Container style={{ marginTop: 75, marginBottom: 75 }}>
        <DescriptionCard
          title="Get A Quote"
          image={civilImg2}
          content={
            <Box style={{ paddingBottom: 55 }}>
              Are you in need of fast and high quality service on Piping
              Engineering.
            </Box>
          }
          targetLink="/contact-us"
          targetLinkText="Get Quote"
        />
    <button style={{
                  backgroundColor: "rgba(17, 70, 117, .75)",
                  textShadow: '1px 1px 1px #000',
                  color: 'white',
                  marginTop:30, 
                  padding: 15,
                  paddingLeft:700, 
                  paddingRight:530,
                  position:"absolute"
                }}
                  >
                <a style={{
                  marginLeft:-140,
                }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
            </button>
      </Container>
    </>
  ),
};
