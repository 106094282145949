
import { Typography, Grid, Card, CardContent, CardMedia } from "@material-ui/core";
import "./VerticleSliderCardRound.css"

export default function VerticleSliderCard(props) {
    return (
        <Grid item xs={12} sm={6} md={3} style={{ marginTop: 20 }} >
            <Card className="verticleSliderCard1" square
                style={{  borderRadius:'70%'}}>
                <CardMedia
                    style={{paddingTop: '100%' }}
                    image={props.content.img}
                    title="Paella dish"
                />
                <CardContent className="hiddenSegment2" style={{ backgroundColor: props.themeColor}}>
                    <Typography gutterBottom variant="h5" component="h2" style={{paddingTop:'10px', fontSize: 21, textAlign:'center' }}>
                        {props.content.title}
                    </Typography>
                    <Typography variant="body2" className="textSecondary2" component="p" style={{paddingTop:'20px', textAlign:'center'}}>
                        {props.content.description}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}