import React, { useState, useEffect } from 'react';
import inquiryImage from '../../Data/images/enquiry-image.jpg'
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Typography,
    Box,
    Container,
    Paper,
    Grid,
    Snackbar,
    makeStyles,
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { generateOtp, verifyOtp } from './enquiryService';

const theme = createMuiTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
    },
    container: {
        display: 'flex',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
    },
    imageSection: {
        flex: 1,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    formSection: {
        flex: 1,
        padding: theme.spacing(4),
        backgroundColor: '#ffffff',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    otpInput: {
        width: '40px',
    },
    image: {
        width: '100%',
        maxWidth: '300px',
        marginBottom: theme.spacing(4),
    },
}));

const EnquiryForm = () => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        enquiryType: '',
        phone: '',
        description: '',
    });
    const [showOTP, setShowOTP] = useState(false);
    const [showOTPButton, setShowOTPButton] = useState(false);
    const [otp, setOTP] = useState(['', '', '', '', '', '']);
    const [otpVerified, setOtpVerified] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [canResendOTP, setCanResendOTP] = useState(false);
    const [resendTimer, setResendTimer] = useState(30);
    const [consent, setConsent] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [token, setOtpToken] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let timer;
        if (showOTP && resendTimer > 0) {
            timer = setInterval(() => {
                setResendTimer((prev) => prev - 1);
            }, 1000);
        }
        if (resendTimer === 0) {
            setCanResendOTP(true);
        }
        return () => clearInterval(timer);
    }, [showOTP, resendTimer]);

    useEffect(() => {
        if (formData.phone && formData.email) {
            setShowOTPButton(true)
        }
    }, [formData])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));

        if ((name === 'phone' && value.length === 10) && (name === 'email' && value.includes('@'))) {
            setShowOTPButton(true)
            // setShowOTP(true);
            // setResendTimer(30);
            // setCanResendOTP(false);
        }
    };


    const handleOTPChange = (index, value) => {
        const newOTP = [...otp];
        newOTP[index] = value;
        setOTP(newOTP);

        if (index < 5 && value !== '') {
            const nextInput = document.getElementById(`otp-${index + 1}`);
            if (nextInput) nextInput.focus();
        }

        if (newOTP.every((digit) => digit !== '')) {
            verifyOTP(newOTP.join(''));
        }
    };

    const verifyOTP = async (enteredOTP) => {
        if (!token) return alert("First enter the OTP.");
        try {
            const verified = await verifyOtp(enteredOTP, token)
            console.log('Verifying OTP:', enteredOTP);
            setOtpVerified(true);
            setSnackbarOpen(true);
        } catch (error) {
            alert("There is a problem in verifying otp")
        }
    };

    const resendOTP = async () => {
        setLoading(true)
        try {
            if (!formData.email && !formData.phone) return alert("Please fill email and phone field")
            const data = await generateOtp(formData.email, formData.phone)
            setOtpToken(data?.token)
            setResendTimer(30);
            setCanResendOTP(false);
            setSnackbarOpen(true);
        } catch (error) {
            console.log("Otp sending error", error)
            alert("There is problem in sending otp!")
        } finally{
            setLoading(false)
        }
    };

    const sendOtp = async () => {
        const res = await resendOTP();
        setShowOTP(true);
        setShowOTPButton(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (otpVerified && consent) {
            console.log('Form submitted:', formData);
            setFormSubmitted(true);
        }
    };

    const renderForm = () => (
        <form onSubmit={handleSubmit} className={classes.form} disabled>
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        variant="outlined"
                        disabled={token && !otpVerified}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Business Email ID"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        variant="outlined"
                        disabled={token && !otpVerified}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        required
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth required variant="outlined">
                        <InputLabel id="enquiry-type-label">Enquiry Details</InputLabel>
                        <Select
                            labelId="enquiry-type-label"
                            id="enquiryType"
                            name="enquiryType"
                            value={formData.enquiryType}
                            onChange={handleInputChange}
                            label="Enquiry Details"
                            disabled={token && !otpVerified}
                        >
                            <MenuItem value="general">General Enquiry</MenuItem>
                            <MenuItem value="analysis">Analysis Services</MenuItem>
                            <MenuItem value="3d-modelling">3D Modelling</MenuItem>
                            <MenuItem value="laser-scanning">3D Laser Scanning</MenuItem>
                            <MenuItem value="piping">Piping Engineering</MenuItem>
                            <MenuItem value="structural">Structural Engineering</MenuItem>
                            <MenuItem value="instrumentation">Instrumentation Engineering</MenuItem>
                            <MenuItem value="3d-printing">3D Printing</MenuItem>
                            <MenuItem value="fire-fighting">Fire Fighting Engineering</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Mobile No"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                        variant="outlined"
                        disabled={token && !otpVerified}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Detail Description of the Enquiry"
                        name="description"
                        multiline
                        rows={4}
                        value={formData.description}
                        onChange={handleInputChange}
                        inputProps={{ maxLength: 200 }}
                        required
                        variant="outlined"
                    />
                </Grid>


                {showOTP && (
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>
                            OTP
                        </Typography>
                        <Box display="flex" justifyContent="space-between" marginBottom={2}>
                            {otp.map((digit, index) => (
                                <TextField
                                    key={index}
                                    id={`otp-${index}`}
                                    inputProps={{ maxLength: 1 }}
                                    value={digit}
                                    onChange={(e) => handleOTPChange(index, e.target.value)}
                                    className={classes.otpInput}
                                    variant="outlined"
                                />
                            ))}
                        </Box>
                        {!otpVerified && (
                            <Box marginBottom={2}>
                                <Typography variant="body2" gutterBottom>
                                    Please enter the OTP sent to your phone and email.
                                </Typography>
                                {canResendOTP ? (
                                    <Button onClick={resendOTP} color="primary">
                                        Resend OTP
                                    </Button>
                                ) : (
                                    <Typography variant="body2">
                                        Resend OTP in {resendTimer} seconds
                                    </Typography>
                                )}
                            </Box>
                        )}
                        {otpVerified && (
                            <Typography variant="body2" style={{ color: theme.palette.success.main }}>
                                OTP verified successfully!
                            </Typography>
                        )}
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={consent}
                                onChange={(e) => setConsent(e.target.checked)}
                                required
                                color="primary"
                            />
                        }
                        label="I consent to the processing of my personal data"
                    />
                </Grid>

                {
                    showOTPButton && !token &&
                    <Grid item xs={12}>
                        <Button onClick={sendOtp} color="primary" variant="contained" disabled={loading}>
                            Send OTP
                        </Button>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.submit}
                        disabled={!otpVerified || !consent}
                    >
                        Submit Enquiry
                    </Button>
                </Grid>
            </Grid>
        </form>
    );

    if (formSubmitted) {
        return (
            <Container maxWidth="sm">
                <Paper className={classes.formSection}>
                    <Typography variant="h4" gutterBottom align="center">
                        Thank You!
                    </Typography>
                    <Typography variant="body1" align="center">
                        Your enquiry has been submitted successfully.
                    </Typography>
                </Paper>
            </Container>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <Container maxWidth="lg">
                    <Paper className={classes.container+ " " + "enquiry_form_container_responsive"}>
                        <div className={classes.imageSection} style={{ backgroundImage: `url(${inquiryImage})` }}>
                            {/* <img src={inquiryImage} alt="Enquiry" className={classes.image} /> */}
                            <Typography variant="h4" gutterBottom >
                                We'd Love to Hear from You
                            </Typography>
                            <Typography variant="body1">
                                Have a question or need assistance? Fill out our enquiry form, and our team of experts will get back to you promptly with the information you need.
                            </Typography>
                        </div>
                        <div className={classes.formSection}>
                            <Typography variant="h4" gutterBottom>
                            Send Enquiry
                            </Typography>
                            {renderForm()}
                        </div>
                    </Paper>
                </Container>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={otpVerified ? "OTP verified successfully!" : "OTP resent successfully!"}
            />
        </ThemeProvider>
    );
};

export default EnquiryForm;