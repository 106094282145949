import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import firefightingImg1 from "./../images/pipe_image/pipe_1.jpg";
import firefightingImg2 from "./../images/pipe_image/pipe_2.jpg";
import firefightingImg3 from "./../images/pipe_image/pipe_3.jpg";
import firefightingImg4 from "./../images/Fireengineerng.jpg";
import firefightingImgBanner from './../images/FireProtection.png'

import { Link, NavLink } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

// COMPONENTS IMPORT
import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "./../../components/DescriptionCard";
import DescriptionTextContent from "../../components/shared/description-text-content";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";
import { Box } from "@material-ui/core";

export default {
  serviceName: "Fire Engineering",
  serviceDescription: ``,
  serviceBackgroundImage: firefightingImgBanner,
  serviceSubHeading: ``,
  imageList: ["", ""],
  show: true,
  serviceBody: () => (
    <>
      <Container maxWidth="lg">
        <HeadingText title="FIRE ENGINEERING | FIRE WATER SYSTEM DESIGN | FIRE WATER NETWORK ANALYSIS | FIRE WATER HYDRAULICS | FIRE FIGHTING SYSTEM DESIGN" />
      </Container>

      <HeroGreyTextSection
        content="snwel Engineering®’s Fire Engineering Team has expertise in designing piping layouts for different industries such as Oil & Gas, Mining & Metals, Pharmaceutical, Paper & Pulp, Water Treatment, Power and Petrochemicals."
        subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "The team is familiar with application of codes and standards such as ANSI, ISO, DIN, IS and ASME, and has executed projects involving simple skid designs to large and complex process plants.",
          "As a value engineering partner to Clients, snwel Engineering® also has expertise in space management analysis and applying key space management concepts to optimize the plant, equipment and piping layout, while ensuring adherence to client specifications, codes and standards.",
        ]}
      />
      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <DescriptionTextContent
          title={"OUR FIRE ENGINEERING SERVICE INCLUDES"}
          bodyListData={[
            {
              title: "FIRE DETECTION & ALARM SYSTEMS",
              img: firefightingImg4,
              description:
                "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
            {
              title: "GAS SUPPRESSION",
              img: firefightingImg4,
              description:
                "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
            {
              title: "FIRE EXTINGUISHERS",
              img: firefightingImg4,
              description:
                "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
            {
              title: "AUTOMATIC FIRE SPRINKLER SYSTEMS",
              img: firefightingImg4,
              description:
                "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
          ]}
        />
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>
      <HeroSectionImage
        title={"Fire Water Network Hydraulics"}
        img={firefightingImg4}
        color={true}
        body={{
          title: "Turbo Compressor Services include",
          ulList: [
            "Having understood the firewater demands, the next step is the assessment of required fire storage water capacity and points of use.",
            "A firewater network hydraulic analysis is performed to ensure that the fire water is delivered at the necessary flow rate and pressure at the points of use e.g., sprinklers, deluge valves, hydrants, and monitors. We perform the assessment using state-of-the-art software either Pipenet or Fathom, both of which are NFPA compliant. ",
            "he analysis checks for aspects such as maximum permissible velocity, effect of network blockage etc. It is a dynamic assessment and various scenarios can be checked to ensure that the system performs to performance standards under all foreseeable scenarios. ",
          ],
        }}
        
      />

      <HeroSectionImage
        title={"SERVICES INCULDE"}
        img={firefightingImg4}
        reverse={true}
        body={{
          ulList: [
            "Facility assessments, surveys and analysis",
            "Fire protection system design, acquisition, testing and commissioning",
            "Fire and building code compliance and gap analysis",
            "Fire alarm and mass notification",
            "Fire risk assessment",
            "Smoke management design, testing and commissioning",
            "Fire science research and development",
            "Fire modeling and testing",
          ],
        }}
      />

      <HeroGreyTextSection
        content="We take pride in our piping designs, we know what piping layouts work and why. We go beyond producing ‘Code Compliance Reports’."
        subHeadingTitle="Why To Choose Us?"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]}
      />

      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content:
              "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "End to End",
            content:
              "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content:
              "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          
        />
      </Container>
      <Container style={{ marginTop: 20, marginBottom: 75 }}>
        <DescriptionCard
          title="Get A Quote"
          image={firefightingImg2}
          content={
            <Box style={{ paddingBottom: 55 }}>
             Contact Safety Engineering Solutions today to find out more 
            </Box>
          }
        />
        <button style={{
                  backgroundColor: "rgba(17, 70, 117, .75)",
                  textShadow: '1px 1px 1px #000',
                  color: 'white',
                  marginTop:30, 
                  padding: 15,
                  paddingLeft:700, 
                  paddingRight:530,
                  position:"absolute"
                }}
                  >
                <a style={{
                  marginLeft:-140,
                }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
            </button>
      </Container>
    </>
  ),
};
