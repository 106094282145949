//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/pipe_image/page_blocks/image006.jpg";
import image4 from "./../images/pipe_image/page_blocks/image004.jpg";

import pipeImg1 from "./../images/pipe_image/pipe_1.jpg";
import pipeImg2 from "./../images/pipe_image/pipe_2.jpg";
import pipeImg3 from "./../images/pipe_image/pipe_3.jpg";
import pipeImg4 from "./../images/pipe_image/pipe_4.jpg";


import pipeImgBanner from './../images/projectgallery.jpg'

import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/shared/description-card";
import DescriptionTextContent from "../../components/shared/description-text-content";
import { Box } from "@material-ui/core";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";

export default {
  serviceName: "OUR PROJECT GALLERY",
  serviceDescription: ``,
  show: true,
  serviceBackgroundImage: pipeImgBanner,
  serviceSubHeading: ``,
  imageList: ["", ""],
  serviceBody: () => (
    <>

      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <DescriptionTextContent
          title={"PROJECT GALLERY"}
          bodyListData={[
            {
              title: "Pipe Routing based on PFD/ P&ID",
              img: pipeImg1,
              content:
                "Our Team is capable to handle modern piping routing tools like PDMS, E3D, Plant 3D & Etc.",
            },
            {
              title: "Piping GA Drawings & Isometrics",
              img: pipeImg2,
              content:
                "Developing the Piping arrangement & Isometric drawing from PDMS, E3D, 2D CAD & Etc..",
            },
            {
              title: "Pipe Support Design, Selection & Detailing",
              img: pipeImg3,
              content:
                "Our team capable to design, select & details the Pipe Supports as per layout requirements.",
            },
            {
              title: "Pipe Stress Analysis",
              img: pipeImg4,
              content:
                "Maintaing Piping flexibility during design is important, out team has experience to handle clritical piping routing & required stress analysis.",
            },
            {
              title: "Pipe Stress Analysis",
              img: pipeImg4,
              content:
                "Maintaing Piping flexibility during design is important, out team has experience to handle clritical piping routing & required stress analysis.",
            },
            {
              title: "Pipe Stress Analysis",
              img: pipeImg4,
              content:
                "Maintaing Piping flexibility during design is important, out team has experience to handle clritical piping routing & required stress analysis.",
            },
            {
              title: "Pipe Stress Analysis",
              img: pipeImg4,
              content:
                "Maintaing Piping flexibility during design is important, out team has experience to handle clritical piping routing & required stress analysis.",
            },
            {
              title: "Pipe Stress Analysis",
              img: pipeImg4,
              content:
                "Maintaing Piping flexibility during design is important, out team has experience to handle clritical piping routing & required stress analysis.",
            },
            {
              title: "Pipe Stress Analysis",
              img: pipeImg4,
              content:
                "Maintaing Piping flexibility during design is important, out team has experience to handle clritical piping routing & required stress analysis.",
            },
            {
              title: "Pipe Stress Analysis",
              img: pipeImg4,
              content:
                "Maintaing Piping flexibility during design is important, out team has experience to handle clritical piping routing & required stress analysis.",
            },
            {
              title: "Pipe Stress Analysis",
              img: pipeImg4,
              content:
                "Maintaing Piping flexibility during design is important, out team has experience to handle clritical piping routing & required stress analysis.",
            },
            {
              title: "Pipe Stress Analysis",
              img: pipeImg4,
              content:
                "Maintaing Piping flexibility during design is important, out team has experience to handle clritical piping routing & required stress analysis.",
            },

          ]}
        />
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>

      {/* google from link script    */}
      <button style={{
        backgroundColor: "rgba(17, 70, 117, .75)",
        textShadow: '1px 1px 1px #000',
        color: 'white',
        marginTop: 10,
        marginLeft: 63,
        padding: 5,
        paddingLeft: 700,
        paddingRight: 530,
        position: "absolute"
      }}
      >
        <a style={{
          marginLeft: -140,
        }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
      </button>

    </>
  ),
};
