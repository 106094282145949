//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/pipe_image/page_blocks/image006.jpg";
import image4 from "./../images/pipe_image/page_blocks/image004.jpg";

import pipeImg1 from "./../images/pipe_image/pipe_1.jpg";
import pipeImg2 from "./../images/pipe_image/pipe_2.jpg";
import pipeImg3 from "./../images/pipe_image/pipe_3.jpg";
import pipeImg4 from "./../images/pipe_image/pipe_4.jpg";


import pipeImgBanner from './../images/static_page_images/Plant Design-1-snwel Engineering.jpg'

import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/shared/description-card";
import DescriptionTextContent from "../../components/shared/description-text-content";
import { Box } from "@material-ui/core";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,                                                                        
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";

export default {
  serviceName: "Terms & Conditions",
  serviceDescription: ``,
  show: true, 
  serviceBackgroundImage: pipeImg4 ,
  serviceSubHeading: ``,
  imageList: ["", ""],
  serviceBody: () => (
    <>
      {/* <Container maxWidth="lg">
        <HeadingText title="full range of Piping Engineering services, including site surveys and technical support" />
      </Container> */}

      <HeroGreyTextSection style={{paddingLeft:'100px'}}
        // subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "This website (Site) is owned and operated by snwel Engineering. At snwel Engineering, we recognize that visitors to our Site may be concerned about how we treat the information they provide to us. Snwel's Privacy Policy (Policy) addresses those concerns and applies to information collected and maintained by Snwel.",
          "Except as mandated or needed to comply with law, Snwel does not collect and use personally identifiable information on our Site from individuals unless it is provided to us voluntarily and knowingly. For example, voluntary information is provided when Site visitors complete an online Snwel form or search job openings. In order for Snwel to respond to online requests, certain information is mandatory so that we may communicate with you, thus we request that information.",
          "The only specific personal identification, such as your name or e-mail address, collected via Snwel’s Site is the information you choose to give us. Snwel gathers this information for our use only. Such information will be used as a means of contacting you only with your permission. None of this information is provided to third party groups.",
          "Snwel is not responsible for the content, security, or privacy practices of other websites that we may have a link to or that link to the Snwel Site. Visitors who follow links to such sites are subject to the separate privacy policies thereof.",
          "This Policy may be updated from time-to-time. If you have any questions about Snwel’s Policy, you may contact us at"
        ]}
      />
      
       {/* google from link script    */}
       {/* <button style={{
                  backgroundColor: "rgba(17, 70, 117, .75)",
                  textShadow: '1px 1px 1px #000',
                  color: 'white',
                  paddingTop:30,
                  marginLeft:63,
                  padding: 15,
                  paddingLeft:700, 
                  paddingRight:530,
                  position:"absolute"
                }}
                  >
                <a style={{
                  marginLeft:-140,
                }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
            </button> */}
    
    </>
  ),
};
