import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Container from '@material-ui/core/Container';
import Services from './../../../Data/service';
import { useEffect, useState } from 'react';

export default (props) => {

    let serviceID = props?.match?.params?.serviceID;
    const [serviceData, setServiceData] = useState(Services[serviceID])

    useEffect(() => {
        setServiceData(Services[props?.match?.params?.serviceID])
    }, [props.match.params])

    if (serviceData)
        console.log("SHOW VALUE: ", serviceData?.show);
        return (
            <>
                {
                    (serviceData?.show) ? <Grid container
                    >
                        <Grid container direction="row"
                            justify="center"
                            
                            alignItems="center" xs={12} style={{ height: 450, grad: 'linear-gradient rgba(255,0,0,1)', backgroundSize: 'cover', backgroundImage: `url("${serviceData.serviceBackgroundImage}")` }}>
                            <Typography variant="h2" gutterBottom style={{ backgroundColor: 'rgba(37, 132, 247, 0.75)', textAlign: 'center',  textshadow: '10px 10px', color: '#F0F8FF', fontWeight: '450', textShadow: '2px 2px #000' }}>
                                {serviceData.serviceName}
                            </Typography>
                        </Grid>
                    </Grid> : null
                }

                {/* ACTUAL DATA */}
                <Container maxWidth="lg" style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item>
                            <Typography variant="h6" gutterBottom style={{ textAlign: 'left', color: '#86D8FE' }}>
                                {serviceData.serviceSubHeading}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="p" gutterBottom style={{ fontSize: '18px', textAlign: 'center', color: '#000' }}>
                                {serviceData.serviceDescription}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
                <Grid item style={{ fontSize: '20px' }}>
                    {
                        serviceData?.serviceBody()
                    }
                </Grid>
            </>
        )
    return <h1>WRONG DATA</h1>
}