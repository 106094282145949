import React from "react";
import {Grid, Container, Typography} from "@material-ui/core";
import "./desription-text-content.css";
import VerticleSliderCard from "../../../components/VerticleSliderCard"

export default (props) => {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Container maxWidth={"lg"}>
        <Grid item xs={12} style={{ margin: "10px"}}>
          <Typography variant="h5" gutterBottom className={"headingSectionTitle"}
            style={{ fontWeight: 500 }}>
            <center>{props?.title}</center>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: "30px 0" }}>
          <Grid container spacing={1}>
            {
              props.bodyListData.map((data, index) =>
                <VerticleSliderCard content={data} themeColor="rgb(34, 159, 212)" />
              )
            }
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};



// TO DO : https://www.stork.com/en/capabilities/scaffolding-insulation-painting/passive-fire-protection
// FULL RANGE BLOCk above link
// detail page 2-3 component DONE

// mobile view IN PROGRESS
// FONTS