import React from "react";
import "./card.css";
import { Link } from "react-router-dom";

export class ElegentCard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="el-wrapper">
        <div className="box-up">
          <img className="img img-responsive" src={this.props.imgLink} alt="" />
          <div className="img-info">
            <div className="info-inner">
              {this.props.submenu ? (
                <span className="p-company">
                  <ul id="submenuul">
                    {this.props.submenu
                      ? this.props.submenu.map((menu) => (
                          <li style={{ fontSize: "16px", marginTop: 4 }}>
                            <Link style={{textDecoration: 'none'}} to={menu.link}>{menu.name}</Link>
                          </li>
                        ))
                      : null}
                  </ul>
                </span>
              ) : (
                <Link to={this.props.link} style={{textDecoration: 'none'}}><span
                  className="p-name"
                  style={{ color: "#104775", fontSize: "22px" }}
                >
                  {this.props.name}
                </span></Link>
              )}
            </div>
          </div>
        </div>
        <div className="box-down">
          <div className="h-bg">
            <div className="h-bg-inner" />
          </div>
          <a className="cart">
            <span className="price"style={{width: '100%',textAlign:'center'}}>{this.props.name}</span>
          </a>
        </div>
      </div>
    );
  }
}

export default ElegentCard;
