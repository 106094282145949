//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import pipeImg1 from "./../images/pipe_image/pipe_5.gif";
import pipeImg2 from "./../images/pipe_image/pipe_2.jpg";
import pipeImg3 from "./../images/pipe_image/pipe_3.jpg";
import pipeImg4 from "./../images/pipe_image/pipe_4.jpg";


import pipeImgBanner from './../images/slider/3DPlantEngineering-Banner.jpg'

import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/shared/description-card";
import DescriptionTextContent from "../../components/shared/description-text-content";
import { Box } from "@material-ui/core";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";

export default {
  serviceName: "Plant Engineering",
  serviceDescription: ``,
  show: true,
  serviceBackgroundImage: pipeImgBanner,
  serviceSubHeading: ``,
  imageList: ["", ""],
  serviceBody: () => (
    <>
      <Container maxWidth="lg">
        <HeadingText title="Full range of fire protection services, including site surveys and technical support" />
      </Container>

      <HeroGreyTextSection
        content="snwel Engineering®’s Plant Engineering Team has expertise in designing piping layouts for different industries such as Oil & Gas, Mining & Metals, Pharmaceutical, Paper & Pulp, Water Treatment, Power and Petrochemicals."
        subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "The team is familiar with application of codes and standards such as ANSI, ISO, DIN, IS and ASME, and has executed projects involving simple skid designs to large and complex process plants.",
          "As a value engineering partner to Clients, snwel Engineering® also has expertise in space management analysis and applying key space management concepts to optimize the plant, equipment and piping layout, while ensuring adherence to client specifications, codes and standards.",
        ]}
      />

      {/* <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={image4}
        color={true}
        body={{
          title: "Turbo Compressor Services include",
          ulList: [
            "demo content 1",
            "demo content 2",
            "demo content 3",
            "demo content 4",
            "demo content 5",
          ],
          subContent:
            "We deliver total solutions as a leading independent turbo compressor service provider. With our in depth compressor knowledge and proven technical capabilities, we support customers in repairing, maintaining and optimizing their production facilities in the global oil & gas and petrochemical industries.",
        }}
      /> */}


      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <DescriptionTextContent
          title={"OUR PLANT ENGINEERING SERVICE INCLUDES"}
          bodyListData={[
            {
              title: "TreisTek provides multi-disciplined engineering",
              img: pipeImg1,
              description:
                "TreisTek provides multi-disciplined engineering and project management services including conceptual engineering, front-end engineering, detailed engineering and design, project management controls and estimating, with construction, turnaround, and support services as needed.",
            },
            {
              title: "We utilise the latest 3D modelling",
              img: pipeImg2,
              description:
                "We utilise the latest 3D modelling software for each phase of project design development, from conceptual to definitive design, right through to the production of documentation for construction. We’ll customize our delivery to suit your project development strategy - we are accustomed to roles in a wide range of contractual relationships including due diligence, design consultancy, independent inspection, EPCM, EPC and strategic alliances.",
            },
            {
              title: "From conducting feasibility studies",
              img: pipeImg3,
              description:
                "From conducting feasibility studies, FEED / Basic & Detail engineering during the engineering phase, to providing field engineering services during construction & commissioning, our plant design & process engineering services span across the entire life cycle of projects. ",
            },
            {
              title: "From conducting feasibility studies",
              img: pipeImg3,
              description:
                "From conducting feasibility studies, FEED / Basic & Detail engineering during the engineering phase, to providing field engineering services during construction & commissioning, our plant design & process engineering services span across the entire life cycle of projects. ",
            },
           ,
          ]}
        />
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>


      <HeroSectionImage
        title={"List of Services we offer"}
        img={image3}
        reverse={true}
        body={{
          ulList: [
            "Conceptual and techno-economic feasibility studies",
            "FEED & basic engineering",
            "Pre-bid / proposal engineering",
            "Preparation of process packages",
            "Technology and process licensor selection",
            "Detailed design & engineering",
            "De-bottlenecking studies and troubleshooting",
            "Laser scanning and 3D modelling",
            "As-built documentation     ",
          ],
        }}
      />

      <HeroGreyTextSection
        content="We take pride in our piping designs, we know what piping layouts work and why. We go beyond producing ‘Code Compliance Reports’."
        subHeadingTitle="Why To Choose Us?"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]}
      />

      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <ThreeBoxDetail
          title="SOLUTIONS"
          left={{
            title: "Tailored solutions",
            content:
              "Site surveys and technical support to determine and implement the optimum solution for each situation",
          }}
          right={{
            title: "Tailored solutions",
            content:
              "Site surveys and technical support to determine and implement the optimum solution for each situation",
          }}
          img={image4}
        />
      </Container>

      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content:
              "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "End to End",
            content:
              "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content:
              "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4}
        />
      </Container>

      {/* <ListGreyTextSection
        title="FULL SUITE OF CAPABILITIES TO KEEP YOUR ASSETS RUNNING"
        list={[
          {
            title: "this is dummy title 1",
            content:
              "asdasd asdasda da sd asd asd asd as da sd asd asd this is dummy title 2,asd, asdasd asdasda da sd asd asd asd as da sd asd asd this is dummy title 2.",
          },
          {
            title: "this is dummy title 2",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 3",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 4",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 5",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 6",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 7",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
        ]}
      /> */}
<button style={{
                  backgroundColor: "rgba(17, 70, 117, .75)",
                  textShadow: '1px 1px 1px #000',
                  color: 'white',
                  marginTop:-42,
                  marginLeft:63,
                  padding: 15,
                  paddingLeft:700, 
                  paddingRight:530,
                  position:"absolute"
                }}
                  >
                <a style={{
                  marginLeft:-140,
                }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
            </button>
    </>
  ),
};
