import HomepageRoute from './Homepage';
import ServiceRoute from './Service';
import ContactusRoute from './Contactus';

export default [
    HomepageRoute,
    ServiceRoute,
    ContactusRoute
    
]
