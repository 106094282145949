//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import pipeImg1 from "./../images/pipe_image/pipe_5.gif";
import pipeImg2 from "./../images/pipe_image/pipe_2.jpg";
import pipeImg3 from "./../images/pipe_image/pipe_3.jpg";
import pipeImg4 from "./../images/pipe_image/pipe_4.jpg";


import pipeImgBanner from './../images/slider/Privacy1.jpeg'


import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/shared/description-card";
import DescriptionTextContent from "../../components/shared/description-text-content";
import { Box } from "@material-ui/core";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";

export default {
  serviceName: "Privacy & Policy",
  serviceDescription: ``,
  show: true,
  serviceBackgroundImage: pipeImgBanner,
  serviceSubHeading: ``,
  imageList: ["", ""],
  serviceBody: () => (
    <>
      <Container maxWidth="lg">
        <HeadingText title="Full range of fire protection services, including site surveys and technical support" />
      </Container>

      <HeroGreyTextSection
        content="This website (Site) is owned and operated by snwel Engineering. At snwel Engineering, we recognize that visitors to our Site may be concerned about how we treat the information they provide to us. Snwel's Privacy Policy (Policy) addresses those concerns and applies to information collected and maintained by Snwel."
        paragraphsList={[
          "Except as mandated or needed to comply with law, Snwel does not collect and use personally identifiable information on our Site from individuals unless it is provided to us voluntarily and knowingly. For example, voluntary information is provided when Site visitors complete an online Snwel form or search job openings. In order for Snwel to respond to online requests, certain information is mandatory so that we may communicate with you, thus we request that information.",
          "The only specific personal identification, such as your name or e-mail address, collected via Snwel’s Site is the information you choose to give us. Snwel gathers this information for our use only. Such information will be used as a means of contacting you only with your permission. None of this information is provided to third party groups.",
          "In order to identify usage trends and evaluate our Site, Snwel may collect information provided by our servers. If collected, this information may be compiled and analyzed on an aggregate basis, but only for use by Snwel. Specifically, this information may include your IP address, host name, pages you visit on our site, certain browser information and the referring URL.",
          "The Internet is not completely secure. Unauthorized persons can wrongfully obtain online information. Consequently, even though we use security measures to protect the information under our control, we can’t guarantee that your personally identifiable information will not be accessed by unauthorized persons.",
          "This Site is NOT intended to be used by children and we do not knowingly collect personal information on users under the age of 13. We are fully committed to complying with the United States Children’s Online Privacy Protection Act (COPPA).",
          "Snwel is not responsible for the content, security, or privacy practices of other websites that we may have a link to or that link to the Snwel Site. Visitors who follow links to such sites are subject to the separate privacy policies thereof.",
          "This Policy may be updated from time-to-time. If you have any questions about Snwel’s Policy, you may contact us ",
        ]}
      />

      {/* <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={image4}
        color={true}
        body={{
          title: "Turbo Compressor Services include",
          ulList: [
            "demo content 1",
            "demo content 2",
            "demo content 3",
            "demo content 4",
            "demo content 5",
          ],
          subContent:
            "We deliver total solutions as a leading independent turbo compressor service provider. With our in depth compressor knowledge and proven technical capabilities, we support customers in repairing, maintaining and optimizing their production facilities in the global oil & gas and petrochemical industries.",
        }}
      /> */}

{/* 
      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <DescriptionTextContent
          title={"OUR PLANT ENGINEERING SERVICE INCLUDES"}
          bodyListData={[
            {
              title: "Piping Engineering",
              img: pipeImg1,
              content:
                "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
            {
              title: "Process Engineering",
              img: pipeImg2,
              content:
                "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
            {
              title: "Civi & Structural Engineering",
              img: pipeImg3,
              content:
                "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
            {
              title: "Electrical & Instrumentation Engineering",
              img: pipeImg4,
              content:
                "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
          ]}
        /> */}
        {/* BODY CONTENT DUMMY ADDED HERE */}
      {/* </Container> */}

{/* 
      <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={image3}
        reverse={true}
        body={{
          ulList: [
            "Pipe Stress & Flexibility Analysis",
            "Piping Detailed Drawings (GA Drawings & Isometrics)",
            "Pipe Support Design and Selection",
            "Material Take Off for Piping, Valves and Insulation",
            "Piping System Revamp Engineering",
            "Piping System Integrity Audit",
            "Laser Scanning & As-Built Modelling for Piping Facilities",
            "Advanced 3D modelling of layout",
          ],
        }}
      /> */}
{/* 
      <HeroGreyTextSection
        content="We take pride in our piping designs, we know what piping layouts work and why. We go beyond producing ‘Code Compliance Reports’."
        subHeadingTitle="Why To Choose Us?"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]} */}
      {/* /> */}

      {/* <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <ThreeBoxDetail
          title="SOLUTIONS"
          left={{
            title: "Tailored solutions",
            content:
              "Site surveys and technical support to determine and implement the optimum solution for each situation",
          }}
          right={{
            title: "Tailored solutions",
            content:
              "Site surveys and technical support to determine and implement the optimum solution for each situation",
          }}
          img={image4}
        /> */}
      {/* </Container> */}

      {/* <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content:
              "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "End to End",
            content:
              "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content:
              "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4}
        /> */}
    {/* //  </Container> */}

      {/* <ListGreyTextSection
        title="FULL SUITE OF CAPABILITIES TO KEEP YOUR ASSETS RUNNING"
        list={[
          {
            title: "this is dummy title 1",
            content:
              "asdasd asdasda da sd asd asd asd as da sd asd asd this is dummy title 2,asd, asdasd asdasda da sd asd asd asd as da sd asd asd this is dummy title 2.",
          },
          {
            title: "this is dummy title 2",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 3",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 4",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 5",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 6",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 7",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
        ]}
      /> */}

      {/* <Container style={{ marginTop: 15, marginBottom: 15 }}>
        <DescriptionCard
          title="Get A Quote"
          image={pipeImg2}
          content={
            <Box style={{ paddingBottom: 55 }}>
              Are you in need of fast and high quality service on Piping
              Engineering.
            </Box>
          }
          targetLink=""
          targetLinkText="Get Quote"
        />
      </Container> */}
       
      {/* <body style={{paddingLeft:600,  }} onLoad="document.getElementById('redirect').click">
        <a href="http://www.mydomain.com?ReportID=1" id="redirect" target="_blank">Contact Us</a>
        
      </body> */}
    
    </>
  ),
};
