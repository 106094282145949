import React from "react";
import {Container, Grid, Typography} from "@material-ui/core";
import "./hero-section-image.css";

export default (props) => {
  return props.reverse ? (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{
        backgroundColor: props?.color ? "#EBEBEB" : "white",
        color: "#114675",
        padding: 15,
      }}
    >
      <Container maxWidth="lg">
        <Grid item xs={12} style={{ margin: "5px 0" }}>
          <Typography
            variant="h"
            gutterBottom
            class={"headingSectionImageTitle"}
          >
            <center>{props?.title}</center>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: "10px 0" }}>
          <Grid container className={"headingSectionImageBody"}>
            <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
              {/* IMAGE */}
              <img
                style={{ width: "100%", maxWidth: "400px" }}
                src={props?.img}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ paddingLeft: 20 }}>
              {/* BODY */}
              {props?.body?.title && (
                <Typography
                  variant="h5"
                  gutterBottom
                  class={"ThreeBoxDetail-Heading"}
                  style={{ color: "#114675" }}
                >
                  {props?.title}
                </Typography>
              )}
              {props?.body?.ulList && (
                <ul>
                  {props?.body?.ulList.map((listItem) => (
                    <li>{listItem}</li>
                  ))}
                </ul>
              )}
              {props?.body?.subContent && (
                <Typography
                  variant="p"
                  gutterBottom
                  class={"ThreeBoxDetail-Content"}
                  style={{ color: "#114675", wordBreak: "break-word" }}
                >
                  {props?.body?.subContent}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  ) : (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{
        backgroundColor: props?.color ? "rgb(235, 235, 235)" : "white",
        padding: 15,
        color: "#114675",
      }}
    >
      <Container maxWidth="lg">
        <Grid item xs={12} style={{ margin: "5px 0" }}>
          <Typography
            variant="h"
            gutterBottom
            class={"headingSectionImageTitle"}
          >
            <center>{props?.title}</center>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: "10px 0" }}>
          <Grid container className={"headingSectionImageBody"}>
            <Grid item xs={12} md={6}>
              {/* BODY */}
              {props?.body?.title && (
                <Typography
                  variant="h5"
                  gutterBottom
                  class={"ThreeBoxDetail-Heading"}
                  style={{ color: "#114675", margin: 0 }}
                >
                  {props?.title}
                </Typography>
              )}
              {props?.body?.ulList && (
                <ul>
                  {props?.body?.ulList.map((listItem) => (
                    <li>{listItem}</li>
                  ))}
                </ul>
              )}
              {props?.body?.subContent && (
                <Typography
                  variant="p"
                  gutterBottom
                  class={"ThreeBoxDetail-Content"}
                  style={{ color: "#114675" }}
                >
                  {props?.body?.subContent}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
              {/* IMAGE */}

              <img
                className="headingSectionImageBody-Image"
                style={{ width: "100%", maxWidth: "400px"}}
                src={props?.img}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};
