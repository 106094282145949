import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import ElegentCard from "../../shared/eleg-card";
import { Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";


import {
  Button,
  Paper,
  Grid,
  Container,
  Typography,
  InputBase,
  InputAdornment,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import VerticleSliderCard from "../../VerticleSliderCardRound";
import DescriptionCard from "../../DescriptionCard";
import homepageConfigData from "./homepage.conf";
import inovationImage1 from "./../../../Data/images/homepage_images/Laser-Scanning-Construction.jpg";
import inovationImage2 from "./../../../Data/images/homepage_images/pointcloud.jpg";
import NavbarData from "./../../../Data/navbar.data";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "./homepage.css";
import { useState } from "react";
import { nanoid } from "nanoid";
import EnquiryForm from "../../EnquiryForm";


function Homepage() {
  const [subscriptionFromData, setSubcriptionFormData] = useState("");
  const [open, setOpen] = React.useState(false);
  const [notificationType, setNotificationType] = useState("");

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const onChange = (event) => {
    console.log(event.target.value);
    setSubcriptionFormData(event.target.value);
  };
  const onSubmit = (event) => {
    event.preventDefault();
    console.log("SUBMIT: ", subscriptionFromData);
    var re = /\S+@\S+\.\S+/;
    if (re.test(subscriptionFromData)) {
      setNotificationType("success");
      handleClick();
    } else {
      setNotificationType("err");
      handleClick();
    }
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        {notificationType === "err" ? (
          <Alert onClose={handleClose} severity="error">
            Enter a valid email
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity="success">
            You will be notfied soon
          </Alert>
        )}
      </Snackbar>
      <Carousel
        showIndicators={false}
        showThumbs={false}
        swipeable
        infiniteLoop
        autoPlay
        useKeyboardArrows
      >
        {homepageConfigData &&
          homepageConfigData.carousel.map((banner) => (
            <Grid container>
              <Grid item xs={12}>
                <img
                  alt="Banner"
                  src={banner.imgLink}
                  style={{
                    width: "100%",
                    color: "#fff",
                    height: "60vh",
                    textAlign: "center",
                    background: "#364d79",
                    objectFit: "cover",
                  }}
                />
              </Grid>
              {/* <Grid container component={Paper} square elevation={12} item xs={10} md={4} style={{
                position: 'absolute',
                backgroundColor: "rgba(17, 70, 117, 0.75)",
                top: 15,
                textShadow: '1px 1px 1px #000',
                color: 'white',
                bottom: 15,
                right: 35,
                padding: 25,
                textAlign: 'center',
                width: '100%',
                height: '20%',
              }}>
                <div>
                  <h1>{banner.title}</h1>
                  <p>{banner.content}</p>
                  </div>
              </Grid> */}
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "left",
                  color: "white",
                  textAlign: "left",
                }}
              >
                <Grid
                  container
                  component={Paper}
                  square
                  elevation={12}
                  item
                  xs={10}
                  md={6}
                  style={{
                    backgroundColor: "rgba(17, 70, 117, .75)",
                    textShadow: "1px 1px 1px #000",
                    color: "white",
                    padding: 25,
                  }}
                >
                  <div>
                    <h1>{banner.title}</h1>
                    <p>{banner.content}</p>
                    <button>
                      {" "}
                      <a href="contact-us" id="redirect" target="_blank">
                        Conatct Us
                      </a>
                    </button>
                  </div>
                </Grid>
              </div>
            </Grid>
          ))}
      </Carousel>

      {/* menu list */}
      <Container maxWidth="lg" style={{ marginTop: "5vh" }}>
        <Typography variant="h4" gutterBottom className="titleHeading">
          EXPLORE OUR SERVICES
        </Typography>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {NavbarData.serviceData.map((menuItem) => (
            <Grid item xs={12} md={4} sm={6} lg={4}>
              <ElegentCard
                link={menuItem.link}
                hasChild={menuItem.hasChild}
                name={menuItem.name}
                submenu={menuItem.childs}
                imgLink={menuItem.img}
              />
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* INDUSTRIES section */}
      <Container maxWidth="lg" style={{ marginTop: "10vh" }}>
        <Typography variant="h4" className="titleHeading">
          INDUSTRIES WE SERVE
        </Typography>
        {/* EXPANDING CARD */}
        <Grid container spacing={1} style={{ marginTop: 25 }}>
          {homepageConfigData?.industries.map((el) => (
            <VerticleSliderCard content={el} themeColor="#04AA6D" />
          ))}
        </Grid>
      </Container>

      {/* SOLUTIONS section */}
      {/* <Container maxWidth="lg" style={{ marginTop: '10vh' }}>
        <Typography variant="h4" className="titleHeading" >
          SOLUTIONS
        </Typography>*/}
      {/* EXPANDING CARD */}
      {/*<Grid container spacing={1} style={{ marginTop: 25 }} >
          {homepageConfigData?.solutuion.map(el =>
            <VerticleSliderCard content={el} themeColor="#04AA6D" />
          )}
        </Grid>
          </Container>*/}

      {/* CUSTOMERS section */}
      <Container maxWidth="lg" style={{ marginTop: "10vh" }}>
        <Typography variant="h4" className="titleHeading">
          ABOUT US
        </Typography>
        <Grid container spacing={1} style={{ marginTop: 25 }}>
          <Grid item xs={12} lg={12}>
            <DescriptionCard
              mode="reverse"
              title="OUR MISSION"
              content="To provide quality services and cost effective solutions with commitment building strong relationship."
              image={inovationImage1}
              direction="row"
            />
            <br />
            <DescriptionCard
              mode="reverse"
              title="OUR VISION"
              content="To innovate and enhance in the field of Engineering services, Solutions, Skill Development, Staffing and Product Development with robust planning and technological methodologies."
              image={inovationImage2}
              direction="row-reverse"
            />
            <br />
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
      <Typography variant="h4" className="titleHeading">
        OUR VIDEOS
      </Typography>
      <br />
      <Container
        style={{
          aspectRatio: "16/9",
        }}
      >
        <iframe
          style={{
            width: "100%",
            height: "100%",
          }}
          src="https://www.youtube.com/embed/7m6cPJgu7t0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Container>

      <Container style={{ padding: 20 }}>
        <br />
        <br />
        <br />
        <Typography variant="h4" className="titleHeading mb-4">
          OUR CLIENTS
        </Typography>
        <div>
          <Swiper
          spaceBetween={20}
          slidesPerView={1}
          modules={[Navigation, Scrollbar]}
          loop={true}
          scrollbar={{draggable: true}}
          autoplay={{delay: 4000}}
          breakpoints={{
            768: {
              slidesPerView: 3
            },
            1200: {
              slidesPerView: 4
            }
          }}
          >
            {
              homepageConfigData.lgCarousel.map((img, index) => {
                return (
                  <SwiperSlide key={nanoid()}>
                  {/* <p>Hello word</p> */}
                  <div className="" style={{  }}>
                    <img
                      src={img}
                      alt={`logo ${index}`}
                      style={{ maxWidth: "100%", borderRadius: "20%" }}
                    />
                  </div>
                </SwiperSlide>
                )
              })
            }
              
          </Swiper>
          {/* <Carousel
            showIndicators={false}
            showThumbs={false}
            centerSlidePercentage={100 / 3}
            swipeable
            infiniteLoop
            autoPlay
            useKeyboardArrows
          >
            {homepageConfigData.logoCarousel.map((data, index) => {
              return (
                <div
                  style={{
                    background: "white",
                    padding: "10px",
                    marginTop: "20px",
                    marginLeft: "150px",
                    marginRight: "150px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  {data.slides.map((d2, index2) => {
                    return (
                      <div
                        className=""
                        style={{ width: "150px", height: "150px" }}
                      >
                        <img
                          src={d2}
                          alt={`logo ${index2}`}
                          style={{ maxWidth: "100%", borderRadius: "20%" }}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Carousel> */}
        </div>
      </Container>
      {/* <container >
      <Carousel >
        <img src='https://via.placeholder.com/150' alt='imagem' title='imagem' />
        <img src='https://via.placeholder.com/150' alt='imagem' title='imagem' />
        <img src='https://via.placeholder.com/150' alt='imagem' title='imagem' />
        <img src='https://via.placeholder.com/150' alt='imagem' title='imagem' />
        <img src='https://via.placeholder.com/150' alt='imagem' title='imagem' />
    </Carousel>
    </container> */}

    <EnquiryForm/>
    </>
  );
}
export default Homepage;
