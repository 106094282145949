//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import pipeImg1 from "./../images/pipe_image/pipe_1.jpg";
import pipeImg2 from "./../images/pipe_image/pipe_2.jpg";
import pipeImg3 from "./../images/pipe_image/pipe_3.jpg";
import pipeImg4 from "./../images/pipe_image/pipe_4.jpg";

import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/DescriptionCard";
import DescriptionTextContent from "../../components/shared/description-text-content";

import {
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";
import { Box } from "@material-ui/core";

export default {
  serviceName: "DIGITAL MARKETING",
  serviceDescription: ``,
  serviceBackgroundImage:
    "https://www.stork.com/images/made/uploads/banners/fabric-maintenance_1800_300_69_int_s_c1_c_t.jpg",
  serviceSubHeading: ``,
  imageList: ["", ""],
  show: true,
  serviceBody: () => (
    <><>
      <Container maxWidth="lg">
        <HeadingText title="heloo range of fire protection services, including site surveys and technical support" />
      </Container>

      <HeroGreyTextSection
        content="snwel Engineering®’s Piping Team has expertise in designing piping layouts for different industries such as Oil & Gas, Mining & Metals, Pharmaceutical, Paper & Pulp, Water Treatment, Power and Petrochemicals."
        subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "The team is familiar with application of codes and standards such as ANSI, ISO, DIN, IS and ASME, and has executed projects involving simple skid designs to large and complex process plants.",
          "As a value engineering partner to Clients, snwel Engineering® also has expertise in space management analysis and applying key space management concepts to optimize the plant, equipment and piping layout, while ensuring adherence to client specifications, codes and standards.",
        ]} />
      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <DescriptionTextContent
          title={"OUR PIPING ENGINEERING SERVICE INCLUDES"}
          bodyListData={[
            {
              title: "Pipe Stress & Flexibility Analysis",
              img: pipeImg1,
              description: "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
            {
              title: "Piping Detailed Drawings (GA Drawings & Isometrics)",
              img: pipeImg2,
              description: "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
            {
              title: "Pipe Support Design and Selection",
              img: pipeImg3,
              description: "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
            {
              title: "Material Take Off for Piping, Valves and Insulation",
              img: pipeImg4,
              description: "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
          ]} />
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>
      <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={image4}
        color={true}
        body={{
          title: "Turbo Compressor Services include",
          ulList: [
            "demo content 1",
            "demo content 2",
            "demo content 3",
            "demo content 4",
            "demo content 5",
          ],
          subContent: "We deliver total solutions as a leading independent turbo compressor service provider. With our in depth compressor knowledge and proven technical capabilities, we support customers in repairing, maintaining and optimizing their production facilities in the global oil & gas and petrochemical industries.",
        }} />

      <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={image3}
        reverse={true}
        body={{
          ulList: [
            "Pipe Stress & Flexibility Analysis",
            "Piping Detailed Drawings (GA Drawings & Isometrics)",
            "Pipe Support Design and Selection",
            "Material Take Off for Piping, Valves and Insulation",
            "Piping System Revamp Engineering",
            "Piping System Integrity Audit",
            "Laser Scanning & As-Built Modelling for Piping Facilities",
            "Advanced 3D modelling of layout",
          ],
        }} />

      <HeroGreyTextSection
        content="We take pride in our piping designs, we know what piping layouts work and why. We go beyond producing ‘Code Compliance Reports’."
        subHeadingTitle="Why To Choose Us?"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]} />

      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content: "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "End to End",
            content: "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content: "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4} />
      </Container>
      <ListGreyTextSection
        title="FULL SUITE OF CAPABILITIES TO KEEP YOUR ASSETS RUNNING"
        list={[
          {
            title: "this is dummy title 1",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd this is dummy title 2,asd, asdasd asdasda da sd asd asd asd as da sd asd asd this is dummy title 2.",
          },
          {
            title: "this is dummy title 2",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 3",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 4",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 5",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 6",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 7",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
        ]} />
      <Container style={{ marginTop: 75, marginBottom: 75 }}>
        <DescriptionCard
          title="Get A Quote"
          image={pipeImg2}
          content={<Box style={{ paddingBottom: 55 }}>
            Are you in need of fast and high quality service on Piping
          </Box>}

          targetLink="redirect"
          targetLinkText="Get " />
{/* link add */}
        <body style={{paddingLeft:10,  }} onLoad="document.getElementById('redirect').click">
        <a href="http://www.mydomain.com?ReportID=1" id="redirect" target="_blank">Report</a>
      </body>
      </Container>
    </>

      </>
  ),
};
