//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import pipeImg1 from "./../images/pipe_image/pipe_5.gif";
import pipeImg2 from "./../images/pipe_image/pipe_2.jpg";
import pipeImg3 from "./../images/pipe_image/pipe_3.jpg";
import pipeImg4 from "./../images/pipe_image/pipe_4.jpg";


import pipeImgBanner from './../images/slider/Mechanical-Engineering-.jpg'

import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/shared/description-card";
import DescriptionTextContent from "../../components/shared/description-text-content";
import { Box } from "@material-ui/core";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";

export default {
  serviceName: "MECHANICAL ENGINEERING",
  serviceDescription: ``,
  show: true,
  serviceBackgroundImage: pipeImgBanner,
  serviceSubHeading: ``,
  imageList: ["", ""],
  serviceBody: () => (
    <>
      <Container maxWidth="lg">
        <HeadingText title="OUTSOURCE MECHANICAL ENGINEERING SERVICES" />
      </Container>

      <HeroGreyTextSection
        content="snwel Engineering®’s MECHANICAL ENGINEERING Team has expertise in designing piping layouts for different industries such as Oil & Gas, Mining & Metals, Pharmaceutical, Paper & Pulp, Water Treatment, Power and Petrochemicals."
        subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "The team is familiar with application of codes and standards such as ANSI, ISO, DIN, IS and ASME, and has executed projects involving simple skid designs to large and complex process plants.",
          "As a value engineering partner to Clients, snwel Engineering® also has expertise in space management analysis and applying key space management concepts to optimize the plant, equipment and piping layout, while ensuring adherence to client specifications, codes and standards.",
        ]}
      />

      {/* <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={image4}
        color={true}
        body={{
          title: "Turbo Compressor Services include",
          ulList: [
            "demo content 1",
            "demo content 2",
            "demo content 3",
            "demo content 4",
            "demo content 5",
          ],
          subContent:
            "We deliver total solutions as a leading independent turbo compressor service provider. With our in depth compressor knowledge and proven technical capabilities, we support customers in repairing, maintaining and optimizing their production facilities in the global oil & gas and petrochemical industries.",
        }}
      /> */}


      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <DescriptionTextContent
          title={"OUR MECHANICAL ENGINEERING SERVICE INCLUDES"}
          bodyListData={[
            {
              title: "MECHANICAL ENGINEERING SERVICES",
              img: pipeImgBanner,
              description:
                "AMD 3D Mechanical Consultants provides the full-spectrum of Mechanical Engineering Services from Calculation and analysis to Drawing and Piping layouts, Mechanical Engineering Services, CAD Conversion and 3D Modeling. Our professionals work both individually on client projects as well as teams to execute big and complicated projects. Our Engineering team includes Planning, Training team, Project team, Engineering team and Technical advisors as well as support professionals.",
            },
            {
              title: "FINITE ELEMENT ANALYSIS",
              img: pipeImgBanner,
              description:
                "Finite Element Analysis enhances the reliability of product design significantly. It is vital in reducing design cycle fo r end products and eliminating design errors. Every Mechanical Design requires a through testing in practical conditions to withstand thermal and structural modeling and durability checks. It is both costly and time-consuming process.",
            },
            {
              title: "DESIGNING OF PRESSURE VESSELS ",
              img: pipeImgBanner,
              content:
                "AMD 3D Mech Consultants' highly skilled does quality and reliable work with Equipment Engineering Design. We have team members which are experienced over 20 years in the field of equipment engineering design and analysis of Static Equipment. We work according to international standards and codes like TEMA, API, and ASME.",
            },
            {
              title: "OTHER SERVICES",
              img: pipeImg4,
              description:
                "Condition monitoring focuses on recognizing and identifying deterioration factors and measures in order to combat deterioration and to prevent faults. This guarantees the reliability of the factory at the highest possible level. The damage and costs of unplanned shutdown can therefore be prevented, which reduces the maintenance costs.",
            },
          ]}
        />
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>


      <HeroSectionImage
        title={"TYPES OFM ECHANICAL ENGINEERING /FINITE ELEMENT ANALYSIS /DESIGNING OF PRESSURE VESSELS SERVICES"}
        img={image3}
        reverse={true}
        body={{
          ulList: [
            "Design and Calculation Report",
            "Fabrication Drawings",
            "Product Detailing",
            "Mechanical GA Drawings",
            "Finite Element Modeling",
            "Linear and nonlinear static analysis",
            "Storage Tanks",
            "Shell and tube heat Exchangers",
          ],
        }}
      />

      <HeroGreyTextSection
        content="We take pride in our piping designs, we know what piping layouts work and why. We go beyond producing ‘Code Compliance Reports’."
        subHeadingTitle="Why To Choose Us?"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]}
      />

      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <ThreeBoxDetail
          title="SOLUTIONS"
          left={{
            title: "Tailored solutions",
            content:
              "Site surveys and technical support to determine and implement the optimum solution for each situation",
          }}
          right={{
            title: "Tailored solutions",
            content:
              "Site surveys and technical support to determine and implement the optimum solution for each situation",
          }}
          img={image4}
        />
      </Container>

      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content:
              "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "End to End",
            content:
              "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content:
              "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4}
        />
        <button style={{
                  backgroundColor: "rgba(17, 70, 117, .75)",
                  textShadow: '1px 1px 1px #000',
                  color: 'white', 
                  padding: 15,
                  paddingLeft:700, 
                  paddingRight:530,
                  position:"absolute"
                }}
                  >
                <a style={{
                  marginLeft:-140,
                }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
            </button>
      </Container>

    </>
  ),
};
