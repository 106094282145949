import fireFight from "./images/Fire-Fighting-System-snwel-Engineering.jpg";
import industriesPicture from "./images/Material-Handling-System-snwel Engineering.jpg";
import companyLogo1 from "./images/Logo/SNWEL_LOGO.png";
import solutionPicture from "./images/Process Plant Design-snwel-Engineering.jpg";
//import AutoCAD from ".Training/AutoCAD-training.png"


// SERVICES IMAGES
import plant3d from './images/service_offered/3D Plant Design-snwel Engineering.jpg';
import laser3d from './images/service_offered/3D-Laser-Scanning-snwel Engineering.jpg';
import civilStructural from './images/service_offered/Civil-structural-Engineering-snwel Engineering.jpg';
import fireFighting from './images/service_offered/Fire-Fighting-System-snwel Engineering.jpg';
import materialHandling from './images/service_offered/Material-Handling-System-snwel Engineering.jpg';
import mepService from './images/service_offered/MEP Service-snwel Engineering.jpg';
import pipingEngineering from './images/service_offered/Piping-Engineering-snwel Engineering.jpg';
import skillManEngineering from './images/service_offered/Skilled-Man-Power-snwel Engineering.jpg';
import waterSystem from './images/service_offered/Water-System-snwel Engineering.jpg';


export default {
  navbar: [
    {
      name: "Company",
      img: companyLogo1,
      description: "SNWEL Engineering Pvt Ltd. was established in year 2016, to meet the growing demand for multi-discipline engineering services, quality Detailed Engineering and 3D solutions in India and Abroad. Committed to provide solutions effectively in terms of cost, time management and quality of services. Our strong and dynamic team of qualified engineers with versatile experience and expertise strengthens our reach to accomplish challenges in various industries and offer wide range of services.",
      childs: [
        {
          name: "About Us ",
          hasChild: false,
          link: "/service/About_Us",
          img: fireFight,
        },
     
        {
          name: "Why Choose Us",
          hasChild: false,
          link: "/service/Why_Choose_Us",
          img: fireFight,
        },
        
      ]
    },
    {
      name: "Services",
      img: fireFight,
      description: "SNWEL has the full suite of capabilities to keep clients’ assets running optimally and thereby increase their competitive advantage.",
      childs: [
        {
          name: "Plant Engineering",
          hasChild: false,
          link: "/service/Plant_Engineering",
          img: fireFight,
        },
        // {
        //   name: "Water Engineering",
        //   hasChild: false,
        //   link: "/",
        //   img: fireFight,
        // },
        {
          name: "Piping Engineering",
          hasChild: false,
          link: "/service/Piping_Engineering",
          img: fireFight,
        },
        // {
        //   name: "Process Engineering",
        //   hasChild: false,
        //   link: "/",
        //   img: industriesPicture,
        // },
       {
          name: "Mechanical Engineering",
          hasChild: false,
          link: "/service/Mechanical_Engineering",
          img: industriesPicture,
        },
        {
          name: "Fire Engineering",
          hasChild: false,
          link: "/service/Fire_Engineering",
          img: fireFight,
        },
        {
          name: "MEP Engineering",
          hasChild: false,
          link: "/service/MEP_Engineering",
          img: fireFight,
        },
        {
          name: "Civil & Structural Engineering",
          hasChild: false,
          link: "/service/Civil_&_Structural_Engineering",
          img: industriesPicture,
        },
        {
          name: "Instrumetation Engineering",
          hasChild: false,
          link: "/service/Instrumentation_Engineering",
          img: fireFight,
        },
        // {
        //   name: "Material Handling",
        //   hasChild: false,
        //   link: "/",
        //   img: fireFight,
        // },
        // {
        //   name: "Analysis",
        //   hasChild: false,
        //   link: "/",
        //   img: fireFight,
        // },
        {
          name: "Safety",
          hasChild: false,
          link: "/service/Safety_Engineering",
          img: fireFight,
        },
      ],
    },
    {
      name: "Industries",
      img: industriesPicture,
      description: "Industries we serve",
      link: "/industries",
      childs: [
        {
          name: "OIL & GAS REFINERIES",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "MINING & METALS",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "PETROCHEMICALS",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "WATER & ENERGY",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "INDUSTRIAL GASES",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "PAPER & PULP",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "CHEMICAL",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "PROCESSING",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "FOOD & BEVERAGES",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "POWER",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
      ]
    },
    {
      name: "Laser Scanning",
      img: solutionPicture,
      description: "This is where description will be displayed",
      link: "/industries",
      childs: [
        {
          name: "3D Plant Scanning",
          hasChild: false,
          link: "/service/3D_Laser_Scaning",
          img: fireFight,
        },
        {
          name: "Large Part Scanning",
          hasChild: false,
          link: "/service/3D_Laser_Scaning",
          img: fireFight,
        },
        {
          name: "Reverse engineering",
          hasChild: false,
          link: "/service/3D_Laser_Scaning",
          img: fireFight,
        },
        {
          name: "Laser Surveying & Construction",
          hasChild: false,
          link: "/service/3D_Laser_Scaning",
          img: fireFight,
        },
        {
          name: "AEROSPACE",
          hasChild: false,
          link: "/service/3D_Laser_Scaning",
          img: fireFight,
        },
        
        {
          name: "CRIME SCANNING",
          hasChild: false,
          link: "/service/3D_Laser_Scaning",
          img: fireFight,
        },
        
      ]
    },
    {
      name: "3D Printing",
      img: companyLogo1,
      description: "SNWEL Engineering Pvt Ltd. was established in year 2016, to meet the growing demand for multi-discipline engineering services, quality Detailed Engineering and 3D solutions in India and Abroad. Committed to provide solutions effectively in terms of cost, time management and quality of services. Our strong and dynamic team of qualified engineers with versatile experience and expertise strengthens our reach to accomplish challenges in various industries and offer wide range of services.",
     childs:[
      {
        name: "3D Printing",
        hasChild: false,
        link: "/service/3D_Printing",
        img: fireFight,
      },
     ]
      
    },
    {
      name: "Job Training",
      img: companyLogo1,
      description: "SNWEL Engineering Pvt Ltd. was established in year 2016, to meet the growing demand for multi-discipline engineering services, quality Detailed Engineering and 3D solutions in India and Abroad. Committed to provide solutions effectively in terms of cost, time management and quality of services. Our strong and dynamic team of qualified engineers with versatile experience and expertise strengthens our reach to accomplish challenges in various industries and offer wide range of services.",
      childs: [
        {
          name: "PIPING ENGINEERING",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "HVAC ENGINEERING",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "3D PRINTING & MODELING",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "WEB DESIGN",
          hasChild: false,
          link: "/",
          img: industriesPicture,
        },
                {
          name: "DIGITAL MARKETING",
          hasChild: false,
          link: "/",
          img: industriesPicture,
        },
        {
          name: "SEO",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "PLANT DESIGN (PDMS & E3D)",
          hasChild: false,
          link: "/",
          img: fireFight,
        },
        {
          name: "Carrier",
          hasChild: false,
          link: "/service/Carrer",
          img: fireFight,
        },

        
      ],  
    },
    
  ],
  serviceData: [
    {
      name: "3D Plant Engineering",
      link: "3",
      hasChild: true,
      img: plant3d,
      childs: [
        {
          name: "Basic Engineering",
          /*link: "/service/Basic_Engineering",*/
        },
        {
          name: "Piping Engineering",
         /* link: "/service/Piping_Engineering",*/
        },
        {
          name: "Mechanical Engineering",
          /*link: "/service/HVAC_Engineering",*/
        },
        {
          name: "Civil & Structure Engineering",
         /* link: "/service/Civil_&_Structural_Engineering",*/
        },
        {
          name: "Control System Engineering",
        /*  link: "/service/Control_System_Engineering",*/
        },
        {
          name: "Electrical Engineering",
         /* link: "/service/Electrical_Engineering",*/
        },
        {
          name: "Project Engineering",
         /* link: "/service/Project_Engineering",*/
        },
        {
          name: "Cost Engineering",
          /*link: "/service/Cost_Engineering",*/
        },
      ],
    },
    {
      name: "Water System Engineering",
      link: "3",
      hasChild: true,
      img: waterSystem,

      childs: [
        {
          name: "Cooling Tower Engineering",
         /* link: "/service/Cooling_Tower_Engineering",*/
        },
        {
          name: "Water Engineering",
         /* link: "/service/Water_Treatment",*/
        },
        {
          name: "Water Treatment",
         /* link: "/service/Water_Treatment",*/
        },
      ],
    },
    {
      name: "Piping Engineering",
      link: "3",
      hasChild: true,
      img: pipingEngineering,

      childs: [
        {
          name: "Detail Engineering",
         /* link: "/service/",*/
        },
        {
          name: "Plot Plan, Equipment Layout and Piping Layout Engineering",
          /*link: "/service/Isometric_&_Geo_Engineering",*/
        },
        {
          name: "3-D modelling of complete Layout along with Equipment and Piping arrangements",
          /*link: "/service/Isometric_&_Geo_Engineering",*/
        },
        {
          name: "Pipe Stress & Flexibility Analysis",
          /*link: "/service/Isometric_&_Geo_Engineering",*/
        },
        {
          name: "Piping Detailed Drawings (GA Drawings & Isometrics)",
          /*link: "/service/Isometric_&_Geo_Engineering",*/
        },
        {
          name: "Pipe Support Design and Selection",
          /*link: "/service/Isometric_&_Geo_Engineering",*/
        },
        {
          name: "Material Take Off for Piping, Valves and Insulation",
          /*link: "/service/Isometric_&_Geo_Engineering",*/
        },
        {
          name: "Piping System Revamp Engineering",
          /*link: "/service/Isometric_&_Geo_Engineering",*/
        },
        {
          name: "Piping System Integrity Audit",
          /*link: "/service/Isometric_&_Geo_Engineering",*/
        },
        {
          name: "Laser Scanning & As-Built Modelling for Piping Facilities",
          /*link: "/service/Isometric_&_Geo_Engineering",*/
        },
        {
          name: "Isometric & Geo Engineering",
          /*link: "/service/Isometric_&_Geo_Engineering",*/
        },
      ],
    },
    {
      name: "Material Handling System",
      link: "3",
      img: materialHandling,
      hasChild: true,
      childs: [
        {
          name: "Design calculations as per CEMA & IS codes",
         /* link: "/service/",*/
        },
        {
          name: "General Arrangements (GA)/Layouts drawings",
          /*link: "/service/",*/
        },
        {
          name: "Civil & Structural design",
         /* link: "/service/",*/
        },
        {
          name: "Electrical & Instrumentation design",
          /*link: "/service/",*/
        },
        {
          name: "Construction & fabrication detailing",
          /*link: "/service/",*/
        },
        {
          name: "As built documentation",
          /*link: "/service/",*/
        },
      ],
    },
    {
      name: "Fire Fighting & HVAC Engineering",
      link: "3",
      img: fireFighting,
      hasChild: true,
      childs: [
        {
          name: "Wet and Dry-Pipe Fire Sprinkler and Standpipes Design",
          /*link: "/service/",*/
        },
        {
          name: "Early Warning Detection, Smoke Control, Flammable Liquids ",
          /*link: "/service/",*/
        },
        {
          name: "Storage and Explosion Protection systems",
          /*link: "/service/",*/
        },
      ],
    },
    {
      name: "Civil & Structural Engineering",
      link: "3",
      img: civilStructural,
      hasChild: true,
      childs: [
        {
          name: "Analysis and Design",
          /*link: "/service/",*/
        },
        {
          name: "GA and Detailed Drawings",
          /*link: "/service/",*/
        },
        {
          name: "Detailed Quantity Estimation",
          /*link: "/service/",*/
        },
        {
          name: "Fabrication Drawings",
          /*link: "/service/",*/
        },
        {
          name: "Technical Specifications",
          /*link: "/service/",*/
        },
      ],
    },
    {
      name: "3D Laser Scanning",
     /* link: "3",*/
      img: laser3d,
      hasChild: true,
      childs: [
        {
          name: "As Built Existing Condition & verification",
         /* link: "/service/",*/
        },
        {
          name: "Point Cloud from Laser Scan",
          /*link: "/service/",*/
        },
        {
          name: "3D Model from Point Cloud",
          /*link: "/service/",*/
        },
        {
          name: "Clash detection and interference checking",
          /*link: "/service/",*/
        },
        {
          name: "Volume calculations for Stock Piles & Yards",
          /*link: "/service/",*/
        },
        {
          name: "Virtual field-fitting for better Site Co-ordination",
          /*link: "/service/",*/
        },
      ],
    },
    {
      name: "MEP Engineering",
      link: "3",
      img: mepService,

      hasChild: true,
      childs: [
        {
          name: "Mechanical Engineering",
          /*link: "/service/",*/
        },
        {
          name: "Fire Protection",
          /*link: "/service/",*/
        },
        {
          name: "Plumbing Design Service",
          /*link: "/service/",*/
        },
        {
          name: "Electrical & Instrumentation",
          /*link: "/service/",*/
        },
        {
          name: "Infrastructure Design",
          /*link: "/service/",*/
        },
        {
          name: "Master Planning & Consulting",
          /*link: "/service/",*/
        },
      ],
    },
    {
      name: "Staffing Solutions",
      link: "3",
      img: skillManEngineering,
      hasChild: true,
      childs: [
        {
          name: "Man Power Outsource (Contract/ Permanent)",
        /*  link: "/service/",*/
        },
        {
          name: "Skill Development",
         /* link: "/service/",*/
        },
        {
          name: "Work Sharing",
         /* link: "/service/",*/
        },
      ],
    },
  ],
  
};
