
import { Typography, Grid, Card, CardContent, CardMedia } from "@material-ui/core";
import "./VerticleSliderCard.css"

export default function VerticleSliderCard(props) {
    return (
        <Grid item xs={12} sm={6} md={3} style={{ marginTop: 10 }} >
            <Card className="verticleSliderCard" square
                style={{  }}>
                <CardMedia
                    style={{paddingTop: '99%'}}
                    image={props.content.img}
                    title="Paella dish"
                />
                <CardContent className="hiddenSegment" style={{backgroundColor: props.themeColor}}>
                    <Typography gutterBottom variant="h5" component="h2" style={{fontSize: 21}}>
                        {props.content.title}
                    </Typography>
                    <Typography variant="body2" className="textSecondary" component="p">
                        {props.content.description}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}