//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/pipe_image/page_blocks/image006.jpg";
import image4 from "./../images/pipe_image/page_blocks/image004.jpg";

import pipeImg1 from "./../images/pipe_image/pipe_1.jpg";
import pipeImg2 from "./../images/pipe_image/pipe_2.jpg";
import pipeImg3 from "./../images/pipe_image/pipe_3.jpg";
import pipeImg4 from "./../images/pipe_image/pipe_4.jpg";


import pipeImgBanner from './../images/static_page_images/Plant Design-1-snwel Engineering.jpg'

import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/shared/description-card";
import DescriptionTextContent from "../../components/shared/description-text-content";
import { Box } from "@material-ui/core";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,                                                                        
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";

export default {
  serviceName: "Piping Engineering",
  serviceDescription: ``,
  show: true, 
  serviceBackgroundImage: pipeImgBanner,
  serviceSubHeading: ``,
  imageList: ["", ""],
  serviceBody: () => (
    <>
      <Container maxWidth="lg">
        <HeadingText title="full range of Piping Engineering services, including site surveys and technical support" />
      </Container>

      <HeroGreyTextSection
        content="snwel Engineering®’s Piping Team has expertise in designing piping layouts for different industries such as Oil & Gas, Mining & Metals, Pharmaceutical, Paper & Pulp, Water Treatment, Power and Petrochemicals."
        subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "The team is familiar with application of codes and standards such as ANSI, ISO, DIN, IS and ASME, and has executed projects involving simple skid designs to large and complex process plants.",
          "As a value engineering partner to Clients, snwel Engineering® also has expertise in space management analysis and applying key space management concepts to optimize the plant, equipment and piping layout, while ensuring adherence to client specifications, codes and standards.",
        ]}
      />

      {/* <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={image4}
        color={true}
        body={{
          title: "Turbo Compressor Services include",
          ulList: [
            "demo content 1",
            "demo content 2",
            "demo content 3",
            "demo content 4",
            "demo content 5",
          ],
          subContent:
            "We deliver total solutions as a leading independent turbo compressor service provider. With our in depth compressor knowledge and proven technical capabilities, we support customers in repairing, maintaining and optimizing their production facilities in the global oil & gas and petrochemical industries.",
        }}
      /> */}


      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <DescriptionTextContent
          title={"OUR PIPING ENGINEERING SERVICE INCLUDES"}
          bodyListData={[
            {
              title: "Pipe Routing based on PFD/ P&ID",
              img: pipeImg1,
              content: 
              "Our Team is capable to handle modern piping routing tools like PDMS, E3D, Plant 3D & Etc.",
            },
            {
              title: "Piping GA Drawings & Isometrics",
              img: pipeImg2,
              content:
                "Developing the Piping arrangement & Isometric drawing from PDMS, E3D, 2D CAD & Etc..",
            },
            {
              title: "Pipe Support Design, Selection & Detailing",
              img: pipeImg3,
              content:
                "Our team capable to design, select & details the Pipe Supports as per layout requirements.",
            },
            {
              title: "Pipe Stress Analysis",
              img: pipeImg4,
              content:
                "Maintaing Piping flexibility during design is important, out team has experience to handle clritical piping routing & required stress analysis.",
            },
          ]}
        />  
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>


      <HeroSectionImage
        title={"TYPES OF SERVICES"}
        img={image3}
        reverse={true}
        body={{
          ulList: [
            "3D Modelling of Piping System with associated Equipments",
            "Material Selection - PMS",
            "Piping Detailed Drawings (GA Drawings & Isometrics)",
            "Pipe Support Design, Selection & Detailing",
            "Material Take Off for Piping, Valves and Insulation",
            "Piping System Revamp Engineering",
            "Piping System Integrity Audit",
            "Laser Scanning & As-Built Modelling for Piping Facilities",
            "Integrated Engineering Aproach: Advanced 3D modelling of layout",
            "Pipe Stress/ Flexibility Analysis",
          ],
        }}
      />

      <HeroGreyTextSection
        content="We take pride in our piping designs, we know what piping layouts work and why. We go beyond producing ‘Code Compliance Reports’."
        subHeadingTitle="Why To Choose Us?"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]}
      />

      <Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <ThreeBoxDetail
          title="SOLUTIONS"
          left={{
            title: "Tailored solutions",
            content:
              "Site surveys and technical support to determine and implement the optimum solution for each situation",
          }}
          right={{
            title: "Tailored solutions",
            content:
              "Site surveys and technical support to determine and implement the optimum solution for each situation",
          }}
          img={image4}
        />
      </Container>

     

      <ListGreyTextSection
        title="OUR FULL RANGE PIPING ENGINEERING CAPABILITIES HELPS SMOOTH PROJECT EXECUTION"
        list={[
          {
            title: "this is dummy title 1",
            content:"Our Team is expert in deliveraing the Project in an Integrated Engineering Approach",
          },
          {
            title: "this is dummy title 2",
            content: "WE can execute the project in 3D Engineering Approach from concept to commisioning",
          },
          {
            title: "this is dummy title 3",
            content: "We understand the FEL metholologies & process",
          },
          {
            title: "this is dummy title 4",
            content: "We can assist your project from Pre-Bid stage to project closoure",
          },
          {
            title: "this is dummy title 5",
            content: "Our Team is expertize to conduct review meetings with top EPCM companies",
          },
          {
            title: "this is dummy title 6",
            content: "We can handle the 30%, 60% & 90% Modelling requirements along with required engineering",
          },
          {
            title: "this is dummy title 7",
            content: "We can help your project in reducing the timeline & cost",
          },
        ]}
      />

<Container maxWidth="lg" style={{ padding: "10px", marginTop: 20 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content:"Multi-discipline support can be provided to deliver any specialized engineering requirement in a collaborative approch",
          }}
          center={{
            title: "End to End",
            content:
              "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content:
              "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4}
        />
      </Container>
      
      {/* <Container style={{ marginTop: 75, marginBottom: 75 }}>
        <DescriptionCard
          title="Consult with Us for Your Project Need"
          image={pipeImg2}
          content={
            <Box style={{ paddingBottom: 55 }}>
              Are you in need of fast and high quality service on Piping
              Engineering.
            </Box>
          }
          targetLink=""
          targetLinkText="Get Quote"
        />
      </Container> */}

       {/* google from link script    */}
       <button style={{
                  backgroundColor: "rgba(17, 70, 117, .75)",
                  textShadow: '1px 1px 1px #000',
                  color: 'white',
                  marginTop:-42,
                  marginLeft:63,
                  padding: 15,
                  paddingLeft:700, 
                  paddingRight:530,
                  position:"absolute"
                }}
                  >
                <a style={{
                  marginLeft:-140,
                }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
            </button>
    
    </>
  ),
};
