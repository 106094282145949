import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import civilImg1 from "./../images/pipe_image/pipe_1.jpg";
import civilImg2 from "./../images/pipe_image/pipe_2.jpg";
import civilImg3 from "./../images/pipe_image/pipe_3.jpg";
import civilImg4 from "./../images/pipe_image/pipe_4.jpg";
import civilImgBanner from './../images/whychooseus.jpg'

import { Link, NavLink } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

// COMPONENTS IMPORT
import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "./../../components/DescriptionCard";
import DescriptionTextContent from "../../components/shared/description-text-content";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";
import { Box } from "@material-ui/core";

export default {
  serviceName: "",
  serviceDescription: ``,
  serviceBackgroundImage: civilImgBanner,
  serviceSubHeading: ``,
  imageList: ["", ""],
  show: true,
  serviceBody: () => (
    <>
      <Container maxWidth="lg">
        <HeadingText title="Our flexible and multidisciplinary approach along with our technical expertise helps deliver a positive customer experience across projects" />
      </Container>

      <HeroSectionImage
        title={"Why Snwel Engineering?"}
        img={image3}
        reverse={true}
        body={{
          ulList: [
            "Scalable & On-Time Delivery ",
            "Reducing Time and Costs of Projects",
            "Flexible Engagement Models",
            "Maintaining Robust Security Standards",
            "Access to the best-of-the-best skilled resources",
            "Experience in plant engineering tools across various sectors",
            "Practice globally followed SOPS",
            "Adhering to International codes and standards",
          ],
        }}
      />

      <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "1.2Man Hours Of Project Execution Experience",
            content:
              "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "100+  Projects Globally",
            content:
              "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "13+ Years Of Multi-Disciplinary Engineering And Design Experience",
            content:
              "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4}
        />
      </Container>
      <HeroGreyTextSection
        subHeadingTitle="Awards"
        paragraphsList={[
          "Snwel Engineering is bestowed with the prestigious “Drivers of Success” award by Hexagon PPM four years in a row for their innovative application of CAESAR II and CADWorx for customer projects executed globally predominantly North America and Europe. This accolade is a testimony of our robust capability and goal accomplishments through accurate and efficient designs for our EPC customers.."
        ]}
      />
          <HeroGreyTextSection
        subHeadingTitle="About the Drivers of Success Award:"
        paragraphsList={[
          "The Drivers of Success award recognizes innovative application/utilization of different Hexagon Software Products, like CADWorx, CAESAR II, and more by various companies for their yearlong effort on solving problems for customers. The accolade honors impressive project results that have displayed significant benefits from inter-disciplinary collaboration and the integration of the products."
        ]}
      />

      <Container style={{ marginTop: 75, marginBottom: 75 }}>
        <DescriptionCard
          title="Get A Quote"
          image={civilImg2}
          content={
            <Box style={{ paddingBottom: 55 }}>
              Are you in need of fast and high quality service on Piping
              Engineering.
            </Box>
          }
          targetLink="/contact-us"
          targetLinkText="Get Quote"
        />
      </Container>
    </>
  ),
};
