// import plant3dEngineering from './plant3d-engineering';
import plant3dEngineerning from './plant3dEngineering';
import pipingEngineering from './piping-engineering';
import processEngineerign from './process-engineering';
import mechanicalEngineering from './mechanical-engineering';
import fireFighting from './firefighting-engineering';
import hvacEngineering from './havac-engineering';
import civilEngineerig from './civil-engineering';
import instrumentationEngineering from './instrumentation-engineering';
import electricalEngineering from './electrical-engineering';
import materialHandling from './material-handling';

import basicEngineering from './basic-engineering';
import controlSystemEngineering from './control-system-engineering';
import projectEngineering from './project-engineering';
import costEngineering from './cost-engineering';
import coolingTowerEngineering from './cooling-tower-engineering';
//import waterEngineering from './water-tower-engineering';
import isometricGeoEngineering from './isometric-geo-engineering';


import test from './firefighting-engineering';
import aboutus from './aboutus';
import piping from './piping';
import printing from './printing';
import digitalmarketing from './digitalmarketing';
import plantdesign from './plantdesign';
import hvac from './hvac';
import webdesign from './webdesign';
import Whychoose from './Whychoose';
import carrier from './carrier';
import printingservice from './printingservice';
import laserscanningservice from './laserscanningservice';
import analysis from './analysis';
import waterengineering from './waterengineering';
import safety1 from './safety1';
import oilgas from './oilgas';
import privacy from './privacy';
import Blog from './Blog';
import projectgalery from './projectgalery';
import terms from './terms';
import PrivacyPloicy from './PrivacyPloicy';
















export default {
    'Plant_Engineering': plant3dEngineerning,
    'Piping_Engineering': pipingEngineering,
    'Fire_Engineering': fireFighting,
    'Process_Engineering': processEngineerign,
    'Mechanical_Engineering': mechanicalEngineering,
    'MEP_Engineering': hvacEngineering,
    'Civil_&_Structural_Engineering': civilEngineerig,
    'Instrumentation_Engineering': instrumentationEngineering,
    'Electrical_Engineering': electricalEngineering,
    'Material_Handling': materialHandling,

    'Basic_Engineering': basicEngineering,
    'Control_System_Engineering': controlSystemEngineering,
    'Project_Engineering': projectEngineering,
    'Cost_Engineering': costEngineering,
    'Cooling_Tower_Engineering': coolingTowerEngineering,
    // 'Water_Treatment': waterEngineering,
    'Isometric_&_Geo_Engineering': isometricGeoEngineering,

    'Service_Plant': plant3dEngineerning,
    'test': test,
    'piping': piping,
    'printing': printing,
    'digitalmarketing': digitalmarketing,
    'plantdesign': plantdesign,
    'hvac': hvac,
    'Contact_Us': webdesign,
    'Why_Choose_Us': Whychoose,
    'Carrer': carrier,
    '3D_Printing': printingservice,
    '3D_Laser_Scaning': laserscanningservice,
    'analysis': analysis,
    'waterengineering': waterengineering,
    'Safety_Engineering': safety1,
    'oilgas': oilgas,
    'privacy': privacy,
    'Blog': Blog,
    'Project_Gallery': projectgalery,

    'About_Us': aboutus,
    'Terms_Conditions': terms,
    'Privacy_Policy': PrivacyPloicy
    
    

    
}