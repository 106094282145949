import {
    Typography, Button, Grid, Box, Card, CardContent, CardMedia,
} from "@material-ui/core";
import "./DescriptionCard.css"
import { Link } from "react-router-dom"

export default function DescriptionCard(props) {
    return (
        <Card square component={Grid} container direction={props.direction}
            style={{ marginTop: 5, background: "#229FD4", color: "white" }}>
            <CardMedia component={Grid} item md={6} xs={12} image={props.image} className="coverPic" />
            <CardContent component={Grid} item md={6} xs={12}>
                <Typography gutterBottom variant="h5" component="h2">
                    {props.title}
                </Typography>
                <Typography variant="body" style={{ lineHeight: 1.7, marginBottom: 5 }}>
                    {props.content}
                </Typography>
                <Box pt={3}>
                    <Button variant="contained" disableElevation component={Link}
                        to={props.targetLink}>{props.targetLinkText || 'Read More'}</Button>
                </Box>
            </CardContent>
        </Card>
    );
}