import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/service/3d_lazer_scanning_images/image004.jpg";
import image4 from "./../images/service/3d_lazer_scanning_images/image005.png";

import civilImg1 from "./../images/pipe_image/pipe_1.jpg";
import civilImg2 from "./../images/pipe_image/pipe_2.jpg";
import civilImg3 from "./../images/pipe_image/pipe_3.jpg";
import civilImg4 from "./../images/contact_us.jpg";
import civilImgBanner from './../images/ContactUs.jpg'

import { Link, NavLink } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

// COMPONENTS IMPORT
import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/DescriptionCard";
import DescriptionTextContent from "../../components/shared/description-text-content";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";
import { Box } from "@material-ui/core";
import { CenterFocusStrong } from "@material-ui/icons";
import EnquiryForm from "../../components/EnquiryForm";

export default {
  serviceName: "",
  serviceDescription: ``,
  serviceBackgroundImage: civilImgBanner,
  serviceSubHeading: ``,
  imageList: ["", ""],
  show: true,
  serviceBody: () => (
    <>
      <Container maxWidth="lg">
        <HeadingText title="Send us a message! We’ll get back to you quickly." />
      </Container>

      {/* <HeroGreyTextSection
      
        content=" Snwel Engineering values trust, integrity, and commitment to excellence and believes that each employee has the competence to make the difference. We give high importance to relationships, which gets reflected in the way we work with our customers and interact with our employees."
        // subHeadingTitle="WHAT WE DO"
        paragraphsList={[
          "For a professionally challenging yet economically rewarding career do send in your resume to career@rishabhsoft.com. And, we will surely connect with you for potential opportunities that match your skills.",
          
        ]} */}
      {/* /> */}
      {/* <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <DescriptionTextContent
          title={"Though, Why Work With Snwel? "}
          bodyListData={[
            {
              title: "The equal upskilling environment",
              img: civilImgBanner,
              description:
                " The equal upskilling environment for everyone across levels with rewarding assignments, flexibility to choose their path, and more .",
            },
            {
              title: "Our in-house L&OD group",
              img: civilImgBanner,
              description:
                "Our in-house L&OD group focuses on helping teams gain new skills to outperform and manage not just the current requirements.",
            },
            {
              title: "Our people are encouraged to stretch",
              img: civilImgBanner,
              description:
                "  Our people are encouraged to stretch their capabilities and employ their expertise to support in the delivery of the strategy and contribute to our long-term goals. As a winning team, it helps drive value by focusing on essential opportunities..",
            },
            {
              title: "Material Take Off for Piping, Valves and Insulation",
              img: civilImgBanner,
              description:
                "Our cross-functional work environment offers a unique combination of optimum energy utilization for mind-body integration with modern amenities that promote collaboration and cohesiveness while still relaxing in the creative breakout zones and play area.",
            },
          ]}
        /> */}
        {/* BODY CONTENT DUMMY ADDED HERE */}
      {/* </Container>
      <HeroSectionImage
        title={"Why Work at Snwel Engineering?"}
        img={image3}
        reverse={true}
        body={{
          ulList: [
            "Individual Learning & Career Growth",
            "Training / Certification Assistance",
            "Overseas Career Opportunities",
            "Employee Referral Program",
            "On-Premise Meal Facilities",
            "Relocation Assistance",
            "Provident Fund",
            "Group Insurance Plans",
          ],
        }}
      /> */}

      {/* <HeroGreyTextSection
        // content="We take pride in our piping designs, we know what piping layouts work and why. We go beyond producing ‘Code Compliance Reports’."
        // subHeadingTitle="Why To Choose Us?"
        // paragraphsList={[
        //   "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

        //   "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        // ]}
      /> */}

      {/* <Container maxWidth="lg" style={{ padding: 0, marginTop: 50 }}>
        <AddValue
          title="HOW WE ADD VALUE"
          left={{
            title: "Multiple Area Support",
            content:
              "If multi-discipline support is requested, we have relationships with other companies that specialize and refer clients/projects to each other regularly.",
          }}
          center={{
            title: "End to End",
            content:
              "We support owner and operator companies by giving them the experienced, “We’ve seen that before, and here’s how to fix it” support they need to get up and running and stay running.",
          }}
          right={{
            title: "On-site Experience",
            content:
              "Our on-site experiences along with a history of great mentoring and significant training produce very practical and sound piping designs from a construction, commissioning, and operations perspectives.",
          }}
          img={image4}
        />
      </Container> */}
      {/* <ListGreyTextSection
        title="FULL SUITE OF CAPABILITIES TO KEEP YOUR ASSETS RUNNING"
        list={[
          {
            title: "this is dummy title 1",
            content:
              "asdasd asdasda da sd asd asd asd as da sd asd asd this is dummy title 2,asd, asdasd asdasda da sd asd asd asd as da sd asd asd this is dummy title 2.",
          },
          {
            title: "this is dummy title 2",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 3",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 4",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 5",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 6",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
          {
            title: "this is dummy title 7",
            content: "asdasd asdasda da sd asd asd asd as da sd asd asd",
          },
        ]}
      /> */}
      {/* <h3 style={{marginLeft:560}}>Contact Us</h3> */}
      <Container style={{ marginTop: 75, marginBottom: 75 }}>
        <DescriptionCard
          title="Contact Us"
          image={civilImg4}
          content={
            <Box style={{ paddingBottom: 10 }}>
              Are you in need of fast and high quality service on Piping
              Engineering.
            </Box>
          }
          targetLink="https://www.rishabheng.com/careers/"
          targetLinkText="Click Here"
        />
      </Container>
   <box>
    <EnquiryForm/>
   <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSczUlAB-5Pzo78mllcbxTLFrVMrzXWPhD10vowxUrewQ7cxqQ/viewform?embedded=true" width="1350" height="1050" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
   </box>
    </>

  ),
};
